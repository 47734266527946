<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <div>
    <a class="navbar-brand">
      <span style="color: rgb(240, 240, 240);">Logo</span>
    </a>
  </div>

  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor03"
          aria-controls="navbarColor03"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="isCollapsed = !isCollapsed">
          <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end"
       id="navbarColor03"
       [ngbCollapse]="isCollapsed">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <div class="dropdown d-lg-inline-block me-1">
          <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen">
            <i class="bx bxl-telegram"></i>
            <span *ngIf="newMsg > 0" class="badge bg-danger rounded-pill">{{newMsg}}</span>
          </button>
        </div>
      </li>


      <li class="nav-item">
        <div class="dropdown d-inline-block" ngbDropdown (click)="setReaded()">
          <button type="button"
                  class="btn header-item noti-icon"
                  id="page-header-notifications-dropdown" ngbDropdownToggle>
                  <i class="bx bx-bell " [ngClass]="{'bx-tada': newNotif > 0 }"></i>
                  <span *ngIf="newNotif > 0" class="badge bg-danger rounded-pill">{{newNotif}}</span>
          </button>


          <div style="width: 400px;" class="contenedor dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
            <div class="pt-2" *ngFor="let item of notificacion">
              <div class="row align-items-center w-100 m-0">
                <div class="px-2" style="border-bottom: 1px solid #e6e6e6;">
                  <a href="javascript: void(0);" class="text-reset notification-item" [ngClass]="{'noRead': item.estado == 0 }" (click)="link(item)">
                    <div class="d-flex">
                      <div class="avatar-xs me-3">
                        <img *ngIf="item.avatar" class="me-3 rounded-circle avatar-xs" src="{{ item.avatar }}"  alt="user-pic">
                      </div>
                      <div class="flex-grow-1">
                        <span class="mb-1 font-size-12">
                          {{ item.notificacion }}
                        </span>

                        <div class="text-muted" style="font-size: 10px;">

                          <!--
                          <p class="mb-1">
                            <span><img src="assets/images/transfriendly/StarSmall.png" alt=""></span>
                            Calfica cómo fue tu experiencia
                            <span><img src="assets/images/transfriendly/StarSmall.png" alt=""></span>
                          </p>
                          -->

                          <p class="mb-0">
                            <i class="mdi mdi-clock-outline"></i>
                            {{ item.momento }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>


      <li class="nav-item">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <img class="rounded-circle header-profile-user" src="{{avatar}}" alt="Header Avatar">
            <span class="d-none d-xl-inline-block ms-1">{{ registerUser.alias }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div ngbDropdownMenu pull-right style="left: -75px;">
            <button ngbDropdownItem routerLink="profile">Configuración de la cuenta </button>
            <div ngbDropdownItem>
              <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckChecked">Notificaciones</label>
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="true">
              </div>
            </div>


            <!--
            <div class="dropdown-divider"></div>
            <div ngbDropdownItem>
              <div>
                <img src="assets/images/transfriendly/truck-2.png" style="height: 20px; width: 20px; margin-right: 10px;">
                <label>Transportista</label>
              </div>
            </div>
            -->

            <div class="dropdown-divider" *ngIf="perf > 1"></div>
            <div ngbDropdown class="dropdown" *ngIf="perf > 1">
              <button class="subItemMenu" ngbDropdownToggle>Cambiar de perfil</button>
              <div id="perfil-menu"  ngbDropdownMenu>

                <div ngbDropdownItem  *ngFor="let item of perfilesActivos; let i = index">
                  <div (click)="switchPerfil(i)">
                    <span>{{ item.perfil }}</span>
                    <i class="fa fa-check"></i>
                  </div>
                </div>

                <div (click)="viewModalPerfil()" ngbDropdownItem *ngIf="perfilesPuedeSolicitar.length > 0">
                  + Agregar perfil
                </div>
              </div>
            </div>

            <button ngbDropdownItem (click)="logout()">
              Cerrar sesión
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
