import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class ServicesService {

  constructor(private httpClient:HttpClient){}

  contratotipo():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/contratotipo`)
  }

  tipotransporte():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/tipotransporte`)
  }

  formapago():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/formapago`)
  }

  pagotiempo(id:number):Observable<any>{
    const data = { "idusuario":id }
    return this.httpClient.post(`${API_URL}/general/pagotiempo`,data)
  }
}
