import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardsRoutingModule } from './dashboards-routing.module';
import { UIModule } from '../../shared/ui/ui.module';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbTooltipModule, NgbNavModule, NgbCarouselModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'
import { SimplebarAngularModule } from 'simplebar-angular';

import { DefaultComponent } from './default/default.component';
import { CargadorComponent } from './cargador/cargador.component';
import { TransportistaComponent } from './transportista/transportista.component';
import { AdministradorComponent } from './administrador/administrador.component';
import { SharedModule } from '../../shared/shared.module';
import { PrincipalComponent } from './principal/principal.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { DescargadorComponent } from './descargador/descargador.component';
import { GestionPropuestaComponent } from './gestion-propuesta/gestion-propuesta.component';
import { GestionPujasComponent } from './gestion-propuesta/gestion-pujas/gestion-pujas.component';
import { OfertasComponent } from './ofertas/ofertas.component';
import { GestionAdministradorComponent } from './gestion-administrador/gestion-administrador.component';
//import { HeadPanelDescargadorComponent } from 'src/app/shared/head-panel-descargador/head-panel-descargador.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormatNumberPipePipe } from './pipe/format-number-pipe.pipe';

@NgModule({
  declarations: [
    DefaultComponent,
    CargadorComponent,
    TransportistaComponent,
    AdministradorComponent,
    PrincipalComponent,
    TopBarComponent,
    SideBarComponent,
    DescargadorComponent,
    GestionPropuestaComponent,
    GestionPujasComponent,
    OfertasComponent,
    GestionAdministradorComponent,
    FormatNumberPipePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardsRoutingModule,
    UIModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbCarouselModule,
    NgApexchartsModule,
    SimplebarAngularModule,
    NgbCollapseModule,
    SharedModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ChartsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ]
})

export class DashboardsModule { }
