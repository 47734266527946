import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inf-articulos',
  templateUrl: './inf-articulos.component.html',
  styleUrls: ['./inf-articulos.component.scss']
})

export class InfArticulosComponent {

  @Input() articulos: any[] = [];

}
