import { Component } from '@angular/core';

@Component({
  selector: 'app-descargador',
  templateUrl: './descargador.component.html',
  styleUrls: ['./descargador.component.scss']
})
export class DescargadorComponent {

}
