import { Component } from '@angular/core';

@Component({
  selector: 'app-tarjeta-identificacion',
  templateUrl: './tarjeta-identificacion.component.html',
  styleUrls: ['./tarjeta-identificacion.component.scss']
})

export class TarjetaIdentificacionComponent {

  addSelfie(){}
}
