import { tipoTransporte } from './../models/tipoTransporte.models';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SharingService {
  private tipoTransporte: BehaviorSubject<tipoTransporte> = new BehaviorSubject<tipoTransporte>({ carga_util:'prueba', idtipotransporte:1, nombre:'prueba', tipo_carga:0, anterior:0, actual:0, cubitaje:0, area:0, alto:0 });
  public anterior:number = -1;
  public actual:number = 0;
  public step:number = 0;
  public idtipotransporte:number = 0;
  public resultado_suma:number = 0;
  public cubitaje_art_individual:number = 0;
  public restante_volumen:number = 0;
  public restante_area:number = 0;
  public porcentaje_total:number = 0;
  public largo:number;
  public ancho:number;
  public volumen:number;
  public caja:number = 0;
  public area:number = 0;
  public alto:number = 0;

  #idxObservable$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  idxObservable$ = this.#idxObservable$.asObservable();

  setIdxObservable(data:number){
    this.#idxObservable$.next(data);
  }



  get getTipoTransporte(){
    return this.tipoTransporte.asObservable();
  }

  set setTipoTransporte(data:tipoTransporte){
    this.tipoTransporte.next(data);
  }
}
