export const navbarCargador = [
    {
        routeLink: '/pagina/principal/cargador',
        icon: 'fal fa-list',
        label: 'Tu panel'
    },
    /*
    {
      href: 'https://pagos.transfriendly.es/home',
      icon: 'fal fa-credit-card',
      label: 'Pago de aportes'
    },

    {
        routeLink: 'products',
        icon: 'fal fa-comments',
        label: 'Ayuda'
    },
    {
        routeLink: 'coupens',
        icon: 'fal fa-cog',
        label: 'Configuración'
    }
    */
];


export const navbarDescargador = [
  {
      routeLink: '/pagina/principal/descargador',
      icon: 'fal fa-list',
      label: 'Tu panel'
  },
  /*
  {
      routeLink: 'products',
      icon: 'fal fa-comments',
      label: 'Ayuda'
  },
  {
      routeLink: 'statistics',
      icon: 'fal fa-credit-card',
      label: 'Métodos de pago'
  },
  {
      routeLink: 'coupens',
      icon: 'fal fa-cog',
      label: 'Configuración'
  }
  */
];


export const navbarTransportista = [
  {
      routeLink: '/pagina/principal/transportista',
      icon: 'fal fa-list',
      label: 'Tu panel'
  },
  /*
  {
      routeLink: 'products',
      icon: 'fal fa-comments',
      label: 'Ayuda'
  },
  {
      routeLink: 'coupens',
      icon: 'fal fa-cog',
      label: 'Configuración'
  }
  */
];


export const navbarAdm = [
  {
      routeLink: 'administrador/gestion',
      icon: 'fal fa-tachometer',
      label: 'Dashboard'
  },
  {
      routeLink: '/pagina/principal/administrador',
      icon: 'fal fa-list',
      label: 'Gestión Global'
  },
  /*
  {
      routeLink: 'products',
      icon: 'fal fa-comments',
      label: 'Comentarios'
  },
  {
      routeLink: 'statistics',
      icon: 'fal fa-credit-card',
      label: 'Ayuda'
  },
  {
      routeLink: 'coupens',
      icon: 'fal fa-cog',
      label: 'Configuración'
  }
  */
];
