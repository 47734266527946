<div *ngIf="valido == false">
  <mat-dialog-content>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div style="float: right; cursor: pointer;" (click)="close()">
            <i class="fal fa-times close-icon"></i>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="text-center">
            <img *ngIf="avatar" class="me-3 rounded-circle avatar-xs" src="{{ avatar }}" style="height: 30%; width: 30%;">
          </div>
          <div class="text-center mt-4">
            <div>
              <span style="font-size: 20px; color: black;"><b>{{ lineaUno }}</b></span>
            </div>
            <div>
              <span class="mt-5" style="font-size: 14px;">{{ lineaDos }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center my-2">
          <span class="fa fa-star" [ngClass]="{'califica': calif1 == true }" style="cursor: pointer; font-size: 40px;" (click)="calificar1()"></span>
          <span class="fa fa-star" [ngClass]="{'califica': calif2 == true }" style="cursor: pointer; font-size: 40px;" (click)="calificar2()"></span>
          <span class="fa fa-star" [ngClass]="{'califica': calif3 == true }" style="cursor: pointer; font-size: 40px;" (click)="calificar3()"></span>
          <span class="fa fa-star" [ngClass]="{'califica': calif4 == true }" style="cursor: pointer; font-size: 40px;" (click)="calificar4()"></span>
          <span class="fa fa-star" [ngClass]="{'califica': calif5 == true }" style="cursor: pointer; font-size: 40px;" (click)="calificar5()"></span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica == 1">
    <div class="row text-center">
      <div class="col-sm-6">
        <span class="selecciona" (click)="select1 = !select1" [ngClass]="{'seleccionaItem': select1 == true }">{{ this.cadena1 }}</span>
      </div>
      <div class="col-sm-6">
        <span class="selecciona" (click)="select2 = !select2" [ngClass]="{'seleccionaItem': select2 == true }">{{ this.cadena2 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select3 = !select3" [ngClass]="{'seleccionaItem': select3 == true }">{{ this.cadena3 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select4 = !select4" [ngClass]="{'seleccionaItem': select4 == true }">{{ this.cadena4 }}</span>
      </div>
      <div class="col-sm-12 mt-4">
        <span class="selecciona" (click)="select5 = !select5" [ngClass]="{'seleccionaItem': select5 == true }">{{ this.cadena5 }}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica == 2">
    <div class="row text-center">
      <div class="col-sm-6">
        <span class="selecciona" (click)="select6 = !select6" [ngClass]="{'seleccionaItem': select6 == true }">{{ this.cadena6 }}</span>
      </div>
      <div class="col-sm-6">
        <span class="selecciona" (click)="select7 = !select7" [ngClass]="{'seleccionaItem': select7 == true }">{{ this.cadena7 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select8 = !select8" [ngClass]="{'seleccionaItem': select8 == true }">{{ this.cadena8 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select9 = !select9" [ngClass]="{'seleccionaItem': select9 == true }">{{ this.cadena9 }}</span>
      </div>
      <div class="col-sm-12 mt-4">
        <span class="selecciona" (click)="select10 = !select10" [ngClass]="{'seleccionaItem': select10 == true }">{{ this.cadena10 }}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica == 3">
    <div class="row text-center">
      <div class="col-sm-6">
        <span class="selecciona" (click)="select11 = !select11" [ngClass]="{'seleccionaItem': select11 == true }">{{ this.cadena11 }}</span>
      </div>
      <div class="col-sm-6">
        <span class="selecciona" (click)="select12 = !select12" [ngClass]="{'seleccionaItem': select12 == true }">{{ this.cadena12 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select13 = !select13" [ngClass]="{'seleccionaItem': select13 == true }">{{ this.cadena13 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select14 = !select14" [ngClass]="{'seleccionaItem': select14 == true }">{{ this.cadena14 }}</span>
      </div>
      <div class="col-sm-12 mt-4">
        <span class="selecciona" (click)="select15 = !select15" [ngClass]="{'seleccionaItem': select15 == true }">{{ this.cadena15 }}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica == 4">
    <div class="row text-center">
      <div class="col-sm-6">
        <span class="selecciona" (click)="select16 = !select16" [ngClass]="{'seleccionaItem': select16 == true }">{{ this.cadena16 }}</span>
      </div>
      <div class="col-sm-6">
        <span class="selecciona" (click)="select17 = !select17" [ngClass]="{'seleccionaItem': select17 == true }">{{ this.cadena17 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select18 = !select18" [ngClass]="{'seleccionaItem': select18 == true }">{{ this.cadena18 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select19 = !select19" [ngClass]="{'seleccionaItem': select19 == true }">{{ this.cadena19 }}</span>
      </div>
      <div class="col-sm-12 mt-4">
        <span class="selecciona" (click)="select20 = !select20" [ngClass]="{'seleccionaItem': select20 == true }">{{ this.cadena20 }}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica == 5">
    <div class="row text-center">
      <div class="col-sm-6">
        <span class="selecciona" (click)="select21 = !select21" [ngClass]="{'seleccionaItem': select21 == true }">{{ this.cadena21 }}</span>
      </div>
      <div class="col-sm-6">
        <span class="selecciona" (click)="select22 = !select22" [ngClass]="{'seleccionaItem': select22 == true }">{{ this.cadena22 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select23 = !select23" [ngClass]="{'seleccionaItem': select23 == true }">{{ this.cadena23 }}</span>
      </div>
      <div class="col-sm-6 mt-4">
        <span class="selecciona" (click)="select24 = !select24" [ngClass]="{'seleccionaItem': select24 == true }">{{ this.cadena24 }}</span>
      </div>
      <div class="col-sm-12 mt-4">
        <span class="selecciona" (click)="select25 = !select25" [ngClass]="{'seleccionaItem': select25 == true }">{{ this.cadena25 }}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="valorCalifica > 0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <textarea [(ngModel)]="comentario" class="form-control" rows="3" placeholder="Deja un comentario" ></textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="container mb-3">
      <div class="row">
        <div class="col-sm-6 text-center">
          <button type="button" class="btn btn-secondary" style="width: 70%" (click)="close()">
            Omitir
          </button>
        </div>

        <div class="col-sm-6 text-center">
          <button [disabled]="valorCalifica == 0" type="button" class="btn btn-primary" style="width: 70%" (click)="aceptar()">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-actions>
</div>


<div *ngIf="valido == true">
  <mat-dialog-content>
    <div class="container mt-4">
      <div class="row">
        <div class="col-sm-12 text-center mt-4">
          <img src="assets/images/transfriendly/gracias.png">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center mt-4">
          <span style="font-size: 20px; color: black;"><b>Gracias por tu respuesta</b></span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center mt-5">
          <button type="button" class="btn btn-primary" style="width: 90%" (click)="close()">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
