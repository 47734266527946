<div class="container-fluid">
  <div class="my-2">
    <label style="color: #000000; font-size: 18px;">Gestión global</label>
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Mis publicaciones</a>
          <ng-template ngbNavContent>
            <app-cargador-publicaciones></app-cargador-publicaciones>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>Mis Contrataciones</a>
          <ng-template ngbNavContent>
            <app-mis-contrataciones></app-mis-contrataciones>
          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink >Mensajes</a>
          <ng-template ngbNavContent>
            <app-mensajes [vMessage]="vMessage"></app-mensajes>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
