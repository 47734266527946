import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem('token_transporte'))
    {   return true;  }
    else
    {
      localStorage.removeItem('token_transporte');
      localStorage.removeItem('data');
      localStorage.removeItem('perfDis');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
