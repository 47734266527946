import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { AdministradorService } from '../../pages/dashboards/services/administrador.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import { DialogAnuncioComponent } from '../dialog-anuncio/dialog-anuncio.component';
import { FormBuilder, FormGroup } from '@angular/forms';
interface Lista {
  id:number;
  nombre_cargador: string;
  fecha_viaje: Date;
  codigo_anuncio: string;
  descripcion: string;
}

@Component({
  selector: 'app-lista-pago-anticipado',
  templateUrl: './lista-pago-anticipado.component.html',
  styleUrls: ['./lista-pago-anticipado.component.scss']
})
export class ListaPagoAnticipadoComponent implements OnInit {

  public propuestas: Lista[];
  public pagina: Lista[];

  searchAnticipado: FormGroup;

  constructor(private administradorService:AdministradorService,
              private ngbModal:NgbModal,
              private ngxSpinnerService:NgxSpinnerService,
              private snackBar:MatSnackBar,
              private fb: FormBuilder,
              public dialog: MatDialog,){}

  ngOnInit(): void {
    this.loadSearchOffers();
    this.getPagoAnticipado();
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 1000);
  }

  validarUsuario(id_anuncio:number){
    Swal.fire({
      title:`Confirmanos que es válido este anuncio`,
      icon:'question',
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      showDenyButton: true,
    }).then(resp=>{
      if (resp.isConfirmed) {

        // Parámetros para validar la propuesta
        let data = { id_anuncio, valido: 1 }
        this.administradorService.valoracionPropuesta(data).subscribe(resp => {
          if (resp.code == 200) {
            const idx = this.pagina.findIndex(o => o.id == id_anuncio);
            this.pagina.splice(idx, 1);
            Swal.fire({ icon: 'success', text: 'Anuncio Validado!' })
          } else {
            this.snackBar.open('No se logró la aprobar el anuncio', 'Ok', {
              duration: 3000,
              horizontalPosition: right,
              verticalPosition: bottom,
            });
          }
        });

    }});
  }

  invalidarUsuario(id_anuncio:number){
    Swal.fire({ title: 'Esta seguro de invalidar el Anuncio?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed)
      {
        // Parámetros para invalidar la propuesta
        let data = {id_anuncio,valido:2}
        this.administradorService.valoracionPropuesta(data).subscribe(resp=>{
          if(resp.code == 200){
            const idx = this.pagina.findIndex(o => o.id == id_anuncio);
            this.pagina.splice(idx, 1);
            Swal.fire({ icon: 'success', text: 'Anuncio Rechazado!' })

          }else{
            this.snackBar.open('No se logró invalidar el anuncio','Ok',{
              duration:3000,
              horizontalPosition:right,
              verticalPosition:bottom,
            });
          }
        })
      }
    })
  }

  async viewModalAnuncio(iOffer:Lista){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data ={idanuncio: iOffer.id, viewPresupuesto:true}

    const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
  }

  loadSearchOffers(){
    this.searchAnticipado = this.fb.group({
      nombre:[''],
      codigo:['']
    });
  }

  searchOffers(){
    this.pagina = [];
    this.getPagoAnticipado()
  }

  getPagoAnticipado(){
    this.ngxSpinnerService.show();

    // Toma los valores de parámetros de búsqueda
    let pSearch = this.formatSearch(this.searchAnticipado.value);

    this.administradorService.consultaPropuestas(pSearch).subscribe(resp=>{
      console.log(resp)
      if(resp.estatus == "ok"){
        this.propuestas = resp.response;
        this.pagina = this.propuestas;
      }
    });
    this.stopSpinner();
  }

  //Depura la información del formulario para la búsqueda o filtro
  formatSearch(dataSearchForm:any){
    let pSearch={};
    Object.entries(dataSearchForm).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })
    return pSearch;
  }

  cerrar(){
    this.ngbModal.dismissAll();
  }
}
