import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';


@Component({
  selector: 'app-head-panel-transportista',
  templateUrl: './head-panel-transportista.component.html',
  styleUrls: ['./head-panel-transportista.component.scss']
})


export class HeadPanelTransportistaComponent implements OnInit {

  public registerUser = { 'id':0, 'name':'', 'alias':'', 'perfil':0 }
  viajes:number=0;
  negociacion:number=0;
  contratos:number=0;
  constructor(private servicesService:ServicesService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));

    this.servicesService.headerTransportista().subscribe((resp:any) =>{
      if(resp.cod && resp.cod == 200){
        this.viajes = resp.viajes;
        this.negociacion = resp.negociacion;
        this.contratos = resp.Contrataciones;
      }
    })
  }
}
