import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';

@Component({
  selector: 'app-head-panel',
  templateUrl: './head-panel.component.html',
  styleUrls: ['./head-panel.component.scss']
})

export class HeadPanelComponent implements OnInit {

  public registerUser = { 'id':0, 'name':'', 'alias':'', 'perfil':0 }
  propuestas:number=0;
  negociacion:number=0;
  contratos:number=0;
  constructor(private servicesService:ServicesService){}


  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));

    this.servicesService.headerCargador().subscribe((resp:any) =>{
      if(resp.cod && resp.cod == 200){
        this.propuestas = resp.propuestas;
        this.negociacion = resp.negociacion;
        this.contratos = resp.contratos;
      }
    })
  }

}
