import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from '../../pages/components/viaje-multiple/services.service';

@Component({
  selector: 'app-trayecto-uno',
  templateUrl: './trayecto-uno.component.html',
  styleUrls: ['./trayecto-uno.component.scss']
})


export class TrayectoUnoComponent implements OnInit {

  @Input() formGroupName: string;
  public trayecto: FormGroup;
  public orden:File;
  public descargadores:any;
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public verQuien:boolean;
  public zp:number=0;

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective,
              private servicesService:ServicesService){}

  ngOnInit(): void {
    this.trayecto = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.getDescargadores();
  }

  onFileChange(event){
    if(event.target.files.length > 0){
      this.orden = event.target.files[0]
      this.trayecto.get('orden_entrega').setValue(this.orden)
      this.trayecto.get('orden_entrega_name').setValue(this.orden.name)
    }
  }


  getDescargadores(){
    this.servicesService.descargadores(this.registerUser.id, this.zp).subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.descargadores = resp.response;
      }
    });
  }


  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.trayecto.get(nameFormControl)?.touched ||
        this.trayecto.get(nameFormControl)?.dirty) &&
        this.trayecto.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  invalidateQuienRecibe(nameFormControl: string){
    let valida = { value: false };
    if(this.trayecto.get(nameFormControl)?.touched && this.trayecto.get(nameFormControl)?.value == -1)
    { valida.value = true; }

    return valida
  }

  getQuienrecibe(){
    const quien = this.trayecto.get('quien_recibe').value
    if(quien == 9999)
    { this.verQuien = true
      this.trayecto.get('nombre').addValidators([Validators.required]);
      this.trayecto.get('telefono').addValidators([Validators.required]);
    }
    else
    { this.verQuien = false

      this.trayecto.get('nombre').clearValidators();
      this.trayecto.get('telefono').clearValidators();

      this.trayecto.get('nombre').setValue('')
      this.trayecto.get('dni').setValue('')
      this.trayecto.get('telefono').setValue('')
      this.trayecto.get('correo').setValue('')
    }
  }

  zonaPostal(event: any) {
    this.zp = event.target.value
    this.getDescargadores()
  }
}
