<ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
  <p style="font-size: 20px; color: white">Por favor espere...</p>
</ngx-spinner>

<div class="modal-content" *ngIf="!sended && !sended2" style="width:'700px'">
  <ng-container *ngIf="perfilTransportista == false">
    <div class="row">
      <div class="col-sm-12">
        <span id="title">¿Qué perfil deseas añadir a tu cuenta?</span>
        <div> Al solicitar otro perfil será validado y añadido a tu cuenta. <br>
              Selecciona el que más se ajuste a tus necesidades.
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-sm-6" *ngIf="perfCargador">
        <div class="card cardSombra iPerfil"
          [class.selected]="idPerfil == 2"
          (click)="setPerfil(2)">
          <div class="text-center" style="padding: 25px;">
            <img src="assets/images/transfriendly/charger-dos.png" style="width: 32%">
          </div>

          <div class="text-center">
            <label style="font-size: 18px;"><b>Destinatario</b></label>
          </div>

          <div>
            <p style="font-size: 14px; padding-left: 25px; padding-right: 25px; height: 80px;">
              Publica anuncios de los portes y mercancía que quieres retirar en uno o varios destinos. Elige al mejor transportista.
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="perfTransport">
        <div class="card cardSombra iPerfil"
          [class.selected]="idPerfil == 4"
          (click)="setPerfil(4)">
          <div class="text-center" style="padding: 25px;">
            <img src="assets/images/transfriendly/truck-4.png" style="width: 40%">
          </div>

          <div class="text-center">
            <label style="font-size: 18px;"><b>Transportista</b></label>
          </div>

          <div>
            <p style="font-size: 14px; padding-left: 25px; padding-right: 25px; height: 90px;">
              Oferta pujas por todos los anuncios que quieras y serás contratado como el mejor candidato.
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="perfDescarg">
        <div class="card cardSombra iPerfil"
          [class.selected]="idPerfil == 3"
          (click)="setPerfil(3)">
          <div class="text-center" style="padding: 25px;">
            <img src="assets/images/transfriendly/descargador-1.png" style="width: 14%">
          </div>

          <div class="text-center">
            <label style="font-size: 18px;"><b>Expedidor</b></label>
          </div>
          <p style="font-size: 14px; padding-left: 25px; padding-right: 25px; height: 90px;">
            Envía tu mercancía, fácil y seguro. Lleva el control de lo que expides.
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="perfilTransportista == true">
    <div [formGroup]="documentacion">
      <div class="row mt-3">
        <div class="col-sm-12 text-center">
          <span style="color: black; font-size: 18px;"><b>Transportista</b></span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-12">
          <div class="text-center">
            <label style="color: black;">Adjunte la documentación solicitada para la seguridad de la empresa</label>
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-2">
            <div class="col-sm-6">
              <div>
                <label style="color: black;">Certificado de no adeudo <span style="color: red;">*</span></label>
              </div>

              <div class="mt-1">
                <input type="text"
                      placeholder="Ningun archivo seleccionado"
                      class="form-control"
                      formControlName="certificadoName"
                      style="font-size: 12px; width: 280px; color: #000000;"
                      [ngClass]="{'is-invalid': invalidate('certificadoName').value }">
              </div>


              <div class="mt-1 text-center">
                <button class="contenedor-btn-file" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar documento
                  <label></label>
                  <input type="file"
                        id="fileID"
                        #fileID
                        class="form-control"
                        (change)="onFileChange($event)">
                </button>
              </div>

              <div class="mt-2">
                <label style="color: #000000;">Fecha vencimiento <span style="color: red;">*</span></label>
                <input type="date"
                      placeholder="Fecha vencimiento"
                      class="form-control"
                      name="vencimientoCertificado"
                      formControlName="vencimientoCertificado"
                      style="font-size: 12px; width: 280px; margin-top: -5px;"
                      [ngClass]="{'is-invalid': invalidate('vencimientoCertificado').value }">
              </div>
            </div>

            <div class="col-sm-6">
              <div>
                <label style="color: black;">Seguro de mercancías <span style="color: red;">*</span></label>
              </div>

              <div class="mt-1">
                <input type="text"
                      placeholder="Ningun archivo seleccionado"
                      class="form-control"
                      formControlName="seguroName"
                      style="font-size: 12px; width: 280px;"
                      [ngClass]="{'is-invalid': invalidate('seguroName').value }">
              </div>

              <div class="mt-1">
                <button class="contenedor-btn-file" (click)="fileIDSeguro.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar documento
                  <label></label>
                  <input type="file"
                        id="fileIDSeguro"
                        #fileIDSeguro
                        class="form-control"
                        (change)="onFileChangeSeguro($event)">
                </button>
              </div>


              <div class="mt-2 text-center">
                <label style="color: #000000;">Fecha vencimiento <span style="color: red;">*</span></label>
                <input type="date"
                        placeholder="Fecha vencimiento"
                        class="form-control"
                        name="vencimientoSeguro"
                        formControlName="vencimientoSeguro"
                        style="font-size: 12px; width: 280px; margin-top: -5px;"
                        [ngClass]="{'is-invalid': invalidate('vencimientoSeguro').value }">
              </div>
            </div>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <div class="actions mt-3">
      <div>
        <button type="button"
                style="width: 100%;"
                class="btn btn-primary"
                [disabled] = "!idPerfil"
                (click)="send()">
                Enviar solicitud
        </button>
      </div>

      <div>
        <button type="button" style="width: 100%;" class="btn btn-secondary" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-content" *ngIf="sended" style="width:'400px'">
  <ng-container>
    <span id="title">Solicitud enviada de manera exitosa</span>
    <i class="sidenav-link-icon" class="fa fa-check-circle" style="padding: 0.3em;font-size: 8em;color:#0F4C81"></i>
    <div style="margin-bottom: 1em;">
      Al ser validado el nuevo perfil serás notificado tanto por correo
      electrónico como por la plataforma
    </div>
    <button type="button" style="width: 100%;" class="btn btn-primary" (click)="close()">
      Aceptar
    </button>
  </ng-container>
</div>

<div class="modal-content" *ngIf="sended2" style="width:'400px'">
  <ng-container>
    <span id="title">Se encuentra un perfil pendiente por validar, por favor intente mas tarde</span>
    <i class="sidenav-link-icon" class="fa fa-check-circle" style="padding: 0.3em;font-size: 8em;color:red"></i>
    <div style="margin-bottom: 1em;">
      Al ser validado el nuevo perfil serás notificado tanto por correo
      electrónico como por la plataforma
    </div>
    <button type="button" style="width: 100%;" class="btn btn-primary" (click)="close()">
      Aceptar
    </button>
  </ng-container>
</div>


