<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="myLargeModalLabel">Propuesta</h5>
    <mat-spinner [diameter]="20"
          *ngIf="loading"
          style="display: inline-block;
                 vertical-align: bottom;"></mat-spinner>
    <button *ngIf="!loading" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Información General</a>
            <ng-template ngbNavContent>
              <app-inf-gene [info_anuncio]="infoAnuncio?.info_anuncio[0]" [viewPresupuesto]="viewPresupuesto"></app-inf-gene>
            </ng-template>
          </li>

          <li [ngbNavItem]="2">
            <a ngbNavLink>Articulos</a>
            <ng-template ngbNavContent>
              <app-inf-articulos [articulos]="infoAnuncio?.articulo"></app-inf-articulos>
            </ng-template>
          </li>

          <li [ngbNavItem]="3">
            <a ngbNavLink>Trayecto</a>
            <ng-template ngbNavContent>
              <app-inf-trayecto [trayectos]="infoAnuncio?.trayecto"></app-inf-trayecto>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>

