import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';

@Component({
  selector: 'app-head-panel-descargador',
  templateUrl: './head-panel-descargador.component.html',
  styleUrls: ['./head-panel-descargador.component.scss']
})


export class HeadPanelDescargadorComponent implements OnInit {

  public registerUser = { 'id':0, 'name':'', 'alias':'', 'perfil':0 };
  proximas:number=0;
  realizadas:number=0;

  constructor(private servicesService:ServicesService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));

    this.servicesService.headerDescargador().subscribe((resp:any) =>{
      if(resp.code && resp.code == 200){
        this.proximas = resp.response.anuncios_aceptados;
        this.realizadas = resp.response.anuncios_finalizados;
      }
    })
  }
}
