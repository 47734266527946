<div class="row">
  <div class="col-12">
    <div class="card ">
      <div class="card-body border-bottom">

        <div>
          <form [formGroup]="searchForm" class="d-xl-block">
            <div class="filters">
              <input type="text" onkeydown="return false" class="fecha form-control" placeholder="Fecha viaje"
                onfocus="(this.type='date')" onblur="(this.type= this.value ? 'date' : 'text')"
                formControlName="fecha_viaje">
              <input type="search" class="provincia form-control" placeholder="Provincia" formControlName="provincia">
              <input type="search" class="ciudad form-control" placeholder="Ciudad" formControlName="ciudad">
              <input type="search" class="calle form-control" placeholder="Calle" formControlName="calle">
              <input type="search" class="numero form-control" placeholder="Número" formControlName="numero">
              <input type="search" class="codigoPostal form-control" placeholder="Código Postal"
                formControlName="codigoPostal">

              <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid"
                (click)="search()">
                <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                Filtrar
              </button>
            </div>
          </form>
        </div>

      </div>

      <div class="card-body">
        <ul>
          <li class="item-work"
              *ngFor="let row of listOffers"
              (click)="viewModal(modalDescarga, row.idanuncio, row.trayecto)">
            <div>
              <div class="route">Descarga viaje {{row.ciudad_destino}}</div>
              <div class="date">Fecha:
                <span *ngIf="row.fecha && !row.fecha.includes('0000')">
                  {{(row.fecha | date:'d/MM/yyyy') || ''}}</span>
              </div>
            </div>
            <div style="text-align: right;">
              <div class="payment">
                <span>Destinatario</span>
              </div>
              <div class="loader">{{row.cargador}}</div>
            </div>
          </li>
        </ul>
      </div>

      <mat-paginator [length]="dataSource.length" [pageSize]="size" [pageIndex]="page"
        [pageSizeOptions]="[5,10, 20, 30]" (page)="linkListToPaginator($event)">
      </mat-paginator>

    </div>
  </div>
</div>

<ng-template #modalDescarga let-modal>
  <div style="margin-bottom: -15px;">
    <button type="button"
            class="btn-close"
            style="margin-left: calc(100% - 35px);margin-top: 15px;"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Close click')"></button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-4">La descarga fue realizada con éxito</div>
    <dl>
      <dt>Fecha</dt>
      <dd>{{dataUnload.fecha | date:'dd/MM/yyyy'}}</dd>

      <dt>Orden de carga</dt>
      <dd>{{dataUnload.fichero_inicio}}</dd>

      <dt>Certificado de limpieza</dt>
      <dd>{{dataUnload.fichero_limpieza}}</dd>

      <dt>Orden de descarga</dt>
      <dd>{{dataUnload.fichero_llegada}}</dd>

      <dt>EMCS</dt>
      <dd>{{dataUnload.fichero_emcs}}</dd>

      <dt>CMR</dt>
      <dd>{{dataUnload.fichero_cmr}}</dd>
    </dl>


    <button type="button" class="btn btn-primary w-100" (click)="downloadfiles()">
      Descargar documentos
    </button>
  </div>
</ng-template>
