<label style="display: flex; flex-direction: row; align-items: center; gap: 20px; font-size: 25px;">
    <b style="color:black">Ofertas</b>
</label>

<div class="row mt-2">
    <div class="col-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Todas</a>
                <ng-template ngbNavContent>
                    <app-ofertas-todas></app-ofertas-todas>
                </ng-template>
            </li>

            <li [ngbNavItem]="2">
                <a ngbNavLink>Ofertas guardadas</a>
                <ng-template ngbNavContent>
                    <app-ofertas-guardadas></app-ofertas-guardadas>
                </ng-template>
            </li>

            <li [ngbNavItem]="3">
                <a ngbNavLink>Viajes en proceso</a>
                <ng-template ngbNavContent>
                    <app-viajes-proceso (vMessage)="goToTab($event)"></app-viajes-proceso>
                </ng-template>
            </li>

            <li [ngbNavItem]="4">
                <a ngbNavLink>Viajes realizados</a>
                <ng-template ngbNavContent>
                    <app-viajes-realizados></app-viajes-realizados>
                </ng-template>
            </li>

          <li [ngbNavItem]="5">
            <a ngbNavLink>Mensajes</a>
            <ng-template ngbNavContent>
              <app-mensajes [vMessage]="vMessage" (clearMessage)="clear()"></app-mensajes>
            </ng-template>
          </li>

        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
