import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { bottom, right } from '@popperjs/core';
import { Offer } from 'src/app/core/models/offer.models';
@Component({
  selector: 'app-gestion-propuesta',
  templateUrl: './gestion-propuesta.component.html',
  styleUrls: ['./gestion-propuesta.component.scss']
})
export class GestionPropuestaComponent {

  idAnuncio:string;
  infoAnuncio:any;

  constructor(private route: ActivatedRoute,
    private servicesService: ServicesService,
           private snackBar: MatSnackBar,){

    this.idAnuncio = this.route.snapshot.paramMap.get('idAnuncio');

    this.loadData();
  }

  async loadData(){
    const resp = await this.apiDataAnuncio({idanuncio: this.idAnuncio});
    if(resp['code'] == 200){
      this.infoAnuncio = resp['msj'].response;
    }
    else{
      this.snackBar.open(resp['msj'].response,'Ok',{
        duration:3000,
        horizontalPosition:right,
        verticalPosition:bottom,
      });
    }
  }

  apiDataAnuncio(data){
    return new Promise((resolve, reject) => {
      this.servicesService.getDataAnuncio(data).subscribe((resp: any) => {
        resolve(resp)
      },error=>{
        reject(error)
      })
    })
  }

  get(data:string, section:string=null):any{
    if(section == 'info_anuncio'){
      if(this.infoAnuncio &&
         this.infoAnuncio ['info_anuncio'] &&
         this.infoAnuncio ['info_anuncio'][0][data]){
        return this.infoAnuncio ['info_anuncio'][0][data]
      }else{
        return ''
      }
    }else{
      if(this.infoAnuncio && this.infoAnuncio[data]){
        return this.infoAnuncio[data]
      }else{
        return ''
      }
    }
  }

  goToTab(iMessage:any){
    // this.vMessage = iMessage;
    // this.active = 3;
    //-- Va a controlador de mensajes
  }
}
