<div style="width: 550px;padding: 12px 16px 0">
  <div>
    <form [formGroup]="contactForm">
      <span class="title">
        Contacto Empleado
        <mat-spinner [diameter]="20"
          *ngIf="loading"
          style="display: inline-block;
                 vertical-align: bottom;"></mat-spinner></span>
      <div class="section">
        <div style="grid-column: span 2;">
          <label for="identificacion_conductor">DNI/NIF</label>
          <input class="form-control"
                 type="text"
                 (blur) = "search()"
                 formControlName="identificacion_conductor">
        </div>
        <div>
          <label for="nombre_completo">Nombre empleado</label>
          <input class="form-control"
                 type="text"
                 formControlName="nombre_completo">
        </div>
        <div>
          <label for="num_telefono">Teléfono empleado</label>
          <input class="form-control" type="text" formControlName="num_telefono">
        </div>
        <div>
          <label for="matricula_trailer">Matrícula de la cisterna</label>
          <input class="form-control" type="text" formControlName="matricula_trailer">
        </div>
        <div>
          <label for="matricula_cabina">Matrícula del cabina</label>
          <input class="form-control" type="text" formControlName="matricula_cabina">
        </div>
      </div>
    </form>
  </div>
</div>
<mat-dialog-actions style="padding: 12px 16px;">
  <div class="col-12" >
    <div class="row g-3">
      <div class="col-sm-6">
        <button type="button" class="btn btn-secondary col-sm-7" (click)="close()">
          {{(readonly)?'Cerrar':'Cancelar'}}
        </button>
      </div>
      <div class="col-sm-6" style="text-align: right;" (click)="save()" >
        <button
          type="button"
          class="btn btn-primary col-sm-7"
          *ngIf="!readonly"
          [disabled]="loading || !contactForm.valid">
          {{(id_operacion==2)?'Actualizar':'Guardar'}}
        </button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
