import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../../dashboards/services/services.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-perfil',
  templateUrl: './dialog-perfil.component.html',
  styleUrls: ['./dialog-perfil.component.scss']
})

export class DialogPerfilComponent implements OnInit {

  public registerUser = { 'id':0, 'name':'','alias':'', 'perfil':0 };
  public dataPerf:any;
  public perfil:number = 0;
  public sended:boolean = false;
  public sended2:boolean = false;
  public idPerfil:number;
  public certificadoSeleccionado:boolean = false;
  public seguroSeleccionado:boolean = false;
  public perfilTransportista:boolean = false;
  public documentacion: FormGroup;

  public perfCargador:boolean = false;
  public perfDescarg:boolean = false;
  public perfTransport:boolean = false;


  constructor(private dialogRef: MatDialogRef<DialogPerfilComponent>,
              private ngxSpinnerService:NgxSpinnerService,
              private servicesService:ServicesService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'))
    this.perfil = Number(this.registerUser.perfil);
    this.initForm();
    this.filtraOpciones();
  }

  initForm(){
    this.documentacion = new FormGroup({
      certificado: new FormControl('',[Validators.required]),
      certificadoName: new FormControl('',[Validators.required]),
      vencimientoCertificado: new FormControl('',[Validators.required]),
      seguro: new FormControl('',[Validators.required]),
      seguroName: new FormControl('',[Validators.required]),
      vencimientoSeguro: new FormControl('',[Validators.required]),
    });
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 2000);
  }

  send(){
    if(this.idPerfil == 4 && this.perfilTransportista == false)
    { this.perfilTransportista = true; }
    else
    { this.solicitarRol();  }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  setPerfil(id:number){
    this.idPerfil = id;
  }



  //**********************************************************
  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.documentacion.get(nameFormControl)?.touched ||
        this.documentacion.get(nameFormControl)?.dirty) &&
        this.documentacion.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  onFileChange(event){
    this.documentacion.controls['certificadoName'].setValue(event.target.files[0].name);
    this.documentacion.controls['certificado'].setValue(event.target.files[0]);
    this.certificadoSeleccionado = true;
  }

  onFileChangeSeguro(event){
    this.documentacion.controls['seguroName'].setValue(event.target.files[0].name);
    this.documentacion.controls['seguro'].setValue(event.target.files[0]);
    this.seguroSeleccionado = true;
  }

  solicitarRol(){

    if(this.perfilTransportista){
      if(this.documentacion.invalid){
        Swal.fire({ icon: 'error', text: 'Formulario Invalido!' })
        return
      }
    }

    this.ngxSpinnerService.show();

    let fecha_certificado = this.documentacion.get('vencimientoCertificado').value;
          fecha_certificado = fecha_certificado.substring(8,10) + '/' + fecha_certificado.substring(5,7) + '/' + fecha_certificado.substring(0,4);

    let fecha_seguro = this.documentacion.get('vencimientoSeguro').value;
        fecha_seguro = fecha_seguro.substring(8,10) + '/' + fecha_seguro.substring(5,7) + '/' + fecha_seguro.substring(0,4);

    const data = new FormData;
    data.append("idusuario", String(this.registerUser.id));
    data.append("id_perfil", String(this.idPerfil));

    data.append("certificado", this.documentacion.get('certificado').value);
    data.append("seguro", this.documentacion.get('seguro').value);
    data.append("fecha_certificado", fecha_certificado);
    data.append("fecha_seguro", fecha_seguro);


    this.servicesService.solicitarRol(data).subscribe(resp =>{
      if(resp.code == 200){
        this.sended=true;
      }

      if(resp.code == 300){
        this.sended2=true;
      }
    })

    this.stopSpinner();
  }

  filtraOpciones(){
    this.dataPerf = JSON.parse(localStorage.getItem('perfDis'))
    let arreglo:any;

    arreglo = this.dataPerf.filter(value => value == 2);
    if(arreglo.length > 0){
      this.perfCargador = true
    }

    arreglo = this.dataPerf.filter(value => value == 3);
    if(arreglo.length > 0){
      this.perfDescarg = true
    }

    arreglo = this.dataPerf.filter(value => value == 4);
    if(arreglo.length > 0){
      this.perfTransport = true
    }
  }
}
