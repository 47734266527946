import { Perfil } from './../../core/models/perfiles.models';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdministradorService } from '../../pages/dashboards/services/administrador.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-valida-perfiles',
  templateUrl: './valida-perfiles.component.html',
  styleUrls: ['./valida-perfiles.component.scss']
})


export class ValidaPerfilesComponent implements OnInit {
  public page = 1;
	public pageSize = 10;
	public collectionSize = 0;
  public pagina:Perfil[];
  public perfiles:Perfil[]
  public palabra:string = '';
  public tag:number = 1;

  public usuario:Perfil;
  public motivo:string ="0";
  public observaciones:string;
  public motivoArray:any;
  public historial:any = [];
  public motivoRechazo:string;

  searchUsers: FormGroup;
  listPerfiles:{id:number,descripcion:string}[];

  constructor(private modalService: NgbModal,
              private administradorService:AdministradorService,
              private ngxSpinnerService:NgxSpinnerService,
              private fb: FormBuilder,
              private snackBar:MatSnackBar){
    this.loadSearchUsers();
    this.loadPerfiles();
  }

  ngOnInit(): void {
    this.consultarAllUsuarios();
    this.consultaMotivo();
  }

  getPerfil(id:number):string{
    let p = this.listPerfiles.find((p)=>p.id == id).descripcion;
    return p;
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 1000);
  }

  refreshPagina(){
    this.pagina = this.perfiles.map((data, i) => ({ id: i + 1, ...data })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  checkPerfil(perfil){
    return this.usuario.perfiles_activos &&
            this.usuario.perfiles_activos.includes(perfil);
  }


  largeModal(largeDataModal: any, id?:number){
    this.consultarUsuario(id);
    this.modalService.open(largeDataModal, { size: 'lg', centered: true });
  }


  invalidarUsuario(largeDataModal: any, id?:number){

    Swal.fire({ title: 'Esta seguro de invalidar el Usuario?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed)
      {
        this.modalService.open(largeDataModal, { size: 'lg', centered: true });
        this.consultarUsuario(id);
        this.consultarAllUsuarios();
      }
    })
  }


  rechazarModal(largeDataModal: any){
    this.modalService.open(largeDataModal, { size: 'lg', centered: true });
  }


  validado(){
    Swal.fire({ icon: 'success', text: 'Perfil Validado!' })
  }

  checkValidaUsuario(email:string, status:string, id_usuario:number){
    this.usuario = <Perfil>{id:id_usuario};
    this.validarUsuario(email,status);
  }

  validarUsuario(email:string, status:string){
    if(status == 'Nuevo'){
      this.administradorService.validarUsuario(email,1).subscribe((resp:any) =>{
        if(resp.status == "ok"){
          Swal.fire({ icon: 'success', text: 'Perfil Validado!' })
          this.modalService.dismissAll();
          this.consultarAllUsuarios();
        }
      })
    }else if(status == 'Activo'){
      this.administradorService.validarNuevoRol(this.usuario.id,1).subscribe((resp:any) =>{
        if(resp.status == "ok"){
          Swal.fire({ icon: 'success', text: resp.msj })
          this.modalService.dismissAll();
          this.consultarAllUsuarios();
        }
      })
    }
  }

  loadSearchUsers(){
    this.searchUsers = this.fb.group({
      nombre:[''],
      perfil:['']
    });
  }

  searchUsuarios(){
    this.pagina = [];
    this.consultarAllUsuarios()
  }

  loadPerfiles(){
    this.administradorService.getListPerfiles().subscribe((resp: any) => {
      console.log('Perfiles',resp);
      if(resp.code == 200) this.listPerfiles = resp.response;
    })
  }

  consultarAllUsuarios(){
    this.ngxSpinnerService.show()


    // Toma los valores de parámetros de búsqueda
    let pSearch = this.formatSearch(this.searchUsers.value);

    this.administradorService.consultarAllUsuarios(pSearch).subscribe(resp =>{
      if(resp.status == "ok" && resp.code == "200"){
        this.perfiles = resp.usuarios;
        this.collectionSize = this.perfiles.length;
        this.refreshPagina();
      }else{
        this.snackBar.open(resp['usuarios'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    })

    this.stopSpinner();
  }


  consultarUsuario(id:number){
    this.tag = 1;
    this.usuario = {} as Perfil;
    this.administradorService.consultarUsuario(id).subscribe(resp =>{
      if(resp.status == "ok"){
        if(resp.usuario != null){
          console.log('resp',resp);
          this.usuario = resp.usuario[0];
          this.usuario.perfiles_activos = resp.perfiles_activos.map(p=>parseInt(p.id_perfil));
          this.usuario.perfiles_pendientes = resp.perfiles_pendientes[0];
          this.historial = resp.historial[0];

          let motivo = resp.historial[0].motivo
          let apoyo = this.motivoArray.filter(resp => resp.idmotivo == motivo);
          this.motivoRechazo = apoyo[0].descripcion;
        }
      }
    })
  }


  invalida(email:string, status:string){
    if(status == 'Nuevo'){
      if(this.motivo != "0" && this.observaciones != undefined && email != "")
      {
        this.administradorService.validarUsuario(email,0,this.motivo,this.observaciones).subscribe((resp:any) =>{
          if(resp.status == "ok"){
            Swal.fire('Invalidado!','El usuario ha sido invalidado con exito.','success')
            this.modalService.dismissAll();
            this.consultarAllUsuarios();
          }
        })
      }
      else
      { Swal.fire({ icon: 'error', text: 'Formulario Invalido!' }) }
    }else if(status == 'Activo'){
      this.administradorService.validarNuevoRol(this.usuario.id,2,this.motivo,this.observaciones).subscribe((resp:any) =>{
        if(resp.status == "ok"){
          Swal.fire({ icon: 'success', text: resp.msj })
          this.modalService.dismissAll();
          this.consultarAllUsuarios();
        }
      })
    }
  }

  downloadFile(email:string, archivo:number, nombre:string){
    this.administradorService.downloadFile(email,archivo).subscribe(resp=>{
      saveAs(resp,nombre),
      error => console.error(error)
    })

  }

  changeTag(t:number){
    this.tag = t;
  }

  cerrar(){
    this.modalService.dismissAll();
  }

  //Depura la información del formulario para la búsqueda o filtro
  formatSearch(dataSearchForm:any){
    let pSearch={};
    Object.entries(dataSearchForm).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })
    return pSearch;
  }

  consultaMotivo(){
    this.administradorService.consultaMotivo().subscribe(resp =>{
      if(resp.status == "ok"){
        this.motivoArray = resp.motivo
      }
    })
  }
}
