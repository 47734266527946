import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cargador } from '../../../core/models/cargador.models';
import { Transportista } from '../../../core/models/transportista.models'

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public correo:string ='';

  constructor(public httpClient:HttpClient){}

  login(email: string, password: string):Observable<any>{
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    return this.httpClient.post(`${API_URL}/auth/login`,formData)
  }


  registerCargadorDescargador(cargador: Cargador){
    let formData: any = new FormData();
    formData.append("name", cargador.name);
    formData.append("cif_nif", cargador.cif_nif);
    formData.append("direccion", cargador.direccion);
    formData.append("telefono", cargador.telefono);
    formData.append("telefono_opc", cargador.telefonoOpcional);
    formData.append("email", cargador.email);
    formData.append("email_verification", cargador.email_verification);
    formData.append("perfil", cargador.perfil);

    return this.httpClient.post(`${API_URL}/auth/register`,formData)
  }


  verificarOTP(email: string, otp: number){
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);

    return this.httpClient.post(`${API_URL}/verificarOTP`,formData)
  }


  cambiarPassword(email: string, password: string){
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    return this.httpClient.post(`${API_URL}/cambiarPassword`,formData)
  }


  me(){
    const token = localStorage.getItem('token_transporte');
    let body:any={};
    let header = new HttpHeaders();
        header = header.set('Content-Type','application/json');
        header = header.set('Authorization', `Bearer ${token}`);

    return this.httpClient.post(`${API_URL}/auth/me`,body,{headers: header})
  }


  registerTransportista(transport: Transportista){
    let formData: any = new FormData();
        formData.append("perfil", 4);

    formData.append("tipo", transport.tipoTransport);
    formData.append("name", transport.nombreFiscal);
    formData.append("cif_nif", transport.cif_nif);
    formData.append("direccion", transport.direccion);
    formData.append("telefono", transport.telefono);
    formData.append("email", transport.correo);

    formData.append("certificado", transport.certificado);
    formData.append("fecha_certificado", transport.fechaVencCertificado);
    formData.append("seguro", transport.seguro);
    formData.append("fecha_seguro", transport.fechaVencSeguro);

    formData.append("nombre_admin", transport.nombreAdmin);
    formData.append("cif_nif_admin", transport.cif_nifAdmin);
    formData.append("tel_admin", transport.telefonoAdmin);
    formData.append("email_admin", transport.correoAdmin);

    return this.httpClient.post(`${API_URL}/auth/registerTransportista`,formData)
  }

  enviarotp(email:string){
    let formData: any = new FormData();
    formData.append("email", email);

    return this.httpClient.post(`${API_URL}/enviarotp`,formData)
  }

  getProfileUser(user:{idUser:number,
                      idPerfil:number | string}){
    let formData: any = new FormData();
    formData.append("idusuario", user.idUser);
    formData.append("idperfil", user.idPerfil);
    return this.httpClient.post(`${API_URL}/general/dataUser`,formData)
  }

  //-- Servicio que informa si el usuario se puede calificar o no
  checkIfScore(data){
    data.calificador = JSON.parse(localStorage.getItem('data')).id
    return this.httpClient.post(`${API_URL}/general/existeCalificacion`,data)
  }

  // -- Gestión Puntos de descarga (Descargador) --------------------------

  getDownloadPoints(){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(`${API_URL}/general/pustosdescargador`,
                                  {idusuario:dataLocal.id})
  }

  saveNewPoint(data:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data.idusuario = dataLocal.id
    return this.httpClient.post(`${API_URL}/general/addpuntodescarga`,
                                  data)
  }

  editPoint(data:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data.idusuario = dataLocal.id
    return this.httpClient.post(`${API_URL}/general/updatepuntodescarga`,
                                  data)
  }

  deletePoint(data:any){
    return this.httpClient.post(`${API_URL}/general/eliminapunto`,
                                  data)
  }

  // -- Gestión Documentos (Transportista) --------------------------

  getListDocsTransp(){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(`${API_URL}/general/consultarArchivos`,
                                  {idusuario:dataLocal.id})
  }

  newDocTransp(dataFile:any){
    return this.httpClient.post(`${API_URL}/general/docComplementario`,
                                          dataFile)
  }

  editDocTransp(dataFile:any){
    return this.httpClient.post(`${API_URL}/general/updateArchivo`,
                                          dataFile)
  }

  deleteDocTransp(dataFile:any){
    return this.httpClient.post(`${API_URL}/general/eliminarArchivo`,
                                          dataFile)
  }
  // ---------------

  saveProfileUser(formData:{
                    idusuario:number,
                    alias:string,
                    short_ubi:string,
                    adjuntos:File
                }){
    return this.httpClient.post(`${API_URL}/general/nickname`,formData)
  }

  getPoliticaPrivacidad(){
    return this.httpClient.get(`${API_URL}/general/getpoliticaprivacidad`)
  }

  getTerminosCondiciones(){
    return this.httpClient.get(`${API_URL}/general/getterminoscondiciones`)
  }

  getTerminosContratacion(){
    return this.httpClient.get(`${API_URL}/general/getcondicionesuso`)
  }
}
