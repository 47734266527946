import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViajeContactoComponent } from './viaje-contacto/viaje-contacto.component';
import { bottom, right } from '@popperjs/core';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { DialogAnuncioComponent } from '../dialog-anuncio/dialog-anuncio.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge } from 'rxjs/internal/observable/merge';
import { utils, writeFile } from 'xlsx';
import { viajesProceso } from '../../core/models/viajesProceso.models';
import * as XLSX from 'xlsx';

export interface listInProcess {
  id: number,
  idcontratacion: number,
  codigo_anuncio: string,
  diferencia: number;
  fechasalida: string;
  ciudad_origen: string;
  ciudad_destino: string;
  monto:number;
  deliveryCert:{
    name:string;
    link:string;
  };
  cargador:string;
};

@Component({
  selector: 'app-viajes-proceso',
  templateUrl: './viajes-proceso.component.html',
  styleUrls: ['./viajes-proceso.component.scss']
})
export class ViajesProcesoComponent{

  @Output() vMessage = new EventEmitter<any>();

  public fileName = 'ViajesProceso.xlsx';
  public viajesProceso: viajesProceso[] = [];

  searchForm: FormGroup;

  displayedColumns: string[] = ['codigo',
                                'diferencia',
                                'fechasalida',
                                'ciudad_origen',
                                'ciudad_destino',
                                'monto',
                                // 'deliveryCert',
                                'cargador',
                                'actions',
                              ];
  row:listInProcess = {
    id:null,
    idcontratacion:null,
    codigo_anuncio:null,
    diferencia: 0,
    fechasalida: '-',
    ciudad_origen: '-',
    ciudad_destino: '-',
    monto: 0,
    deliveryCert: {
      name: 'img_3456.jpg',
      link: ''
    },
    cargador: '-'
  };

  dataSource: MatTableDataSource<listInProcess>;

  @ViewChild('empTbSort') empTbSort = new MatSort();

  constructor(public dialog: MatDialog, private snackBar:MatSnackBar, private servicesService:ServicesService, private fb: FormBuilder){
    this.loadSearch();
    this.loadList();
  }

  async loadList(){
    let dataTable = await this.loadData();
    this.dataSource = new MatTableDataSource(<listInProcess[]> dataTable);
    if(this.dataSource.data) this.dataSource.sort = this.empTbSort;
  }

  async loadData(){
    let pSearch={};
    Object.entries(this.searchForm.value).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })

    return new Promise((resolve) => {
      this.servicesService.getListInProcess(pSearch).subscribe((resp: any) => {
        if (resp.code==200 && resp.response) {
          // Da formato a la respuesta de acuerdo con la interface para cada registro
          let listData:listInProcess[] = [];
          listData = resp.response.map(d=>{ return{...this.row, ...d} });
          this.viajesProceso = resp.response.map(respuesta=>{ return{
                                                codigo_anuncio:respuesta.codigo_anuncio,
                                                diferencia:respuesta.diferencia,
                                                fechasalida:respuesta.fechasalida,
                                                ciudad_origen:respuesta.ciudad_origen,
                                                ciudad_destino:respuesta.ciudad_destino,
                                                monto:respuesta.monto,
                                                cargador:respuesta.cargador,
                                              }});
          resolve(listData);
        }
        resolve(null);
      })
    });
  }

  get f() { return this.searchForm.controls; }

  loadSearch(){
    this.searchForm = this.fb.group({
      codigo_anuncio:[''],
      origen:[''],
      destino:[''],
      desde:[''],
      hasta:['']
    });

    merge(this.searchForm.get('desde').valueChanges,
          this.searchForm.get('hasta').valueChanges)
    .subscribe(() =>{
      this.setValidatorsDate(this.searchForm.get('desde').value,this.searchForm.get('hasta').value);
    })
  }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchForm.get('desde').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchForm.get('desde').setErrors({'rango': true});
    }else{
      this.searchForm.get('desde').setErrors(null)
    }
  }

  search(){
    this.dataSource.data = [];
    this.loadList();
  }

  viewContact(data:listInProcess){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={ idcontratacion:data.idcontratacion };
    const dialogRef = this.dialog.open(ViajeContactoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {

      if(data){
        if(data.result){
          this.snackBar.open('Contacto guardado exitosamente','Ok',{
            duration:3000,
            horizontalPosition:right,
            verticalPosition:bottom,
          });
        }else{
          this.snackBar.open(data.message,'Ok',{
            duration:3000,
            horizontalPosition:right,
            verticalPosition:bottom,
          });
        }
      }
    })
  }

  async viewModalAnuncio(iOffer:listInProcess){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data ={idanuncio: iOffer.id};

    const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
  }

  exportexcel() {
    const headings = [['Codigo','Dias restantes','Fecha de Salida','Origen','Destino','Monto','Cargador']];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.viajesProceso, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, this.fileName);
  }

  verChat(dataAnuncio:any){

    this.servicesService.checkChat({idanuncio:dataAnuncio.id}).subscribe((resp: any) => {
      if(resp.code == 200 && resp.id_sala[0].id){
        this.vMessage.emit({id_sala:resp.id_sala[0].id,
                            listsUser:'viajes'});
      }else{
        this.snackBar.open('No hay conversaciones en este anuncio','Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    })
  }
}

