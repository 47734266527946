import { Component } from '@angular/core';

@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.scss']
})
export class OfertasComponent {

  active = 1;
  vMessage:any=null;

  goToTab(iMessage:any){
    this.vMessage = iMessage;
    this.active = 5;
  }

  clear(){
    this.vMessage = null
  }

}
