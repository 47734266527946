import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal-top-bar',
  templateUrl: './horizontal-top-bar.component.html',
  styleUrls: ['./horizontal-top-bar.component.scss']
})


export class HorizontalTopBarComponent implements OnInit {
  public name = 'Angular';
  public isCollapsed = true;


  constructor(private router: Router){}

  ngOnInit(): void {}

  logout(){
    localStorage.removeItem('token_transporte');
    localStorage.removeItem('data');
    this.router.navigate(['/login']);
  }

  verAddTransportista(){

  }
}
