import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-informacion-administrador',
  templateUrl: './informacion-administrador.component.html',
  styleUrls: ['./informacion-administrador.component.scss']
})

export class InformacionAdministradorComponent implements OnInit {

  @Input() formGroupName: string;
  public informacionAdmin: FormGroup;

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.informacionAdmin = this.formGroupDirective.control.get(this.formGroupName) as FormGroup
  }

  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.informacionAdmin.get(nameFormControl)?.touched ||
        this.informacionAdmin.get(nameFormControl)?.dirty) &&
        this.informacionAdmin.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }
}
