<div class="row">
    <div class="col-12">
        <div class="card ">
            <div class="card-body border-bottom">
              <div>
                <form [formGroup]="searchForm"  class="d-xl-block">
                  <div class="filters">
                    <div class="buscar">
                      <div class="app-search position-relative">
                        <input type="search"
                                class="form-control"
                                placeholder="Código anuncio"
                                formControlName="codigo_anuncio">
                        <span class="bx bx-search-alt"></span>
                      </div>
                    </div>
                    <input type="search" class="origen form-control" formControlName="origen" placeholder="Origen">
                    <input type="search" class="destino form-control" formControlName="destino" placeholder="Destino">
                    <input type="text" onkeydown="return false"
                            class="desde form-control" placeholder="Desde"
                            onfocus="(this.type='date')"
                            onblur="(this.type= this.value ? 'date' : 'text')"
                            formControlName="desde">
                    <div class="error errDesde" *ngIf="f.desde.errors?.required">Es obligatorio el rango completo de fechas</div>
                    <div class="error errDesde" *ngIf="f.desde.errors?.rango">La fecha de inicio no puede ser superior a la fecha final</div>
                    <input type="text" onkeydown="return false"
                            class="hasta form-control" placeholder="Hasta"
                            onfocus="(this.type='date')"
                            onblur="(this.type= this.value ? 'date' : 'text')"
                            formControlName="hasta">
                    <button type="button" class="exportar btn btn-secondary" (click)="exportexcel()">
                      Exportar
                    </button>
                    <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid" (click)="search()">
                      <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                      Filtrar
                    </button>

                  </div>
                </form>
              </div>
            </div>

            <div class="card-body">
                <table id="excel-table" mat-table [dataSource]="dataSource" matSort #empTbSort="matSort" >
                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef> Código </th>
                      <td mat-cell *matCellDef="let element"> {{element.codigo_anuncio}} </td>
                    </ng-container>

                    <ng-container matColumnDef="diferencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Días restantes </th>
                      <td mat-cell *matCellDef="let element"> {{element.diferencia}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fechasalida">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de salida </th>
                      <td mat-cell *matCellDef="let element"> {{element.fechasalida}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ciudad_origen">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Origen </th>
                      <td mat-cell *matCellDef="let element"> {{element.ciudad_origen}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ciudad_destino">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Destino </th>
                      <td mat-cell *matCellDef="let element"> {{element.ciudad_destino}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
                      <td mat-cell *matCellDef="let element"> {{element.monto | formatNumberPipe }} €/Tn</td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="deliveryCert">
                      <th mat-header-cell *matHeaderCellDef> Acta de entrega </th>
                      <td mat-cell *matCellDef="let element"> {{element.deliveryCert.name}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="cargador">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinatario </th>
                      <td mat-cell *matCellDef="let element"> {{element.cargador}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Acciones </th>
                      <td mat-cell *matCellDef="let element">
                        <ul class="list-unstyled hstack gap-1 mb-0" style="justify-content: center;">

                          <li data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Contacto Empleado"
                              (click)="viewContact(element)">
                              <a class="btn btn-sm btn-soft-info"
                                 data-bs-toggle="modal"
                                 data-bs-target=".bs-example-modal-lg">
                                <i class="mdi mdi-account"></i>
                            </a>
                          </li>

                          <li data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Ver anuncio">
                              <a class="btn btn-sm btn-soft-primary"
                                data-bs-toggle="modal"
                                data-bs-target=".bs-example-modal-lg"
                                (click)="viewModalAnuncio(element)">
                                <i class="mdi mdi-eye-outline"></i>
                              </a>
                          </li>

                          <li data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Ver Chat"
                              (click)="verChat(element)">
                              <a class="btn btn-sm btn-soft-primary2"
                                 data-bs-toggle="modal"
                                 data-bs-target=".bs-example-modal-lg">
                                 <i class="mdi mdi-chat-processing-outline"></i>
                              </a>
                          </li>

                          <!-- <li data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete">
                              <a class="btn btn-sm btn-soft-danger"
                                 data-bs-toggle="modal"
                                 data-bs-target=".bs-example-modal-lg">
                                 <i class="mdi mdi-close"></i>
                              </a>
                          </li> -->

                        </ul>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
