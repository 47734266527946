import { Observable } from 'rxjs';
import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { SharingService } from '../../core/services/sharing.service';
import { tipoTransporte } from '../../core/models/tipoTransporte.models';
import { Ocupacion } from '../../core/models/ocupacion.models';
import { FormGroup, FormGroupDirective, FormArray, FormControl, Validators } from '@angular/forms';
import { ServicesService } from '../../pages/dashboards/services/services.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-articulos-enviar',
  templateUrl: './articulos-enviar.component.html',
  styleUrls: ['./articulos-enviar.component.scss']
})


export class ArticulosEnviarComponent implements OnDestroy, OnInit {

  @Input() formGroupName: string;

  public enviar: FormGroup;
  public doc:FormGroup;
  private vehiculo$:Observable<tipoTransporte>;
  private vehiculoSubscription: Subscription;
  public tipoCarga:number = 0;
  public cantidad:number = 0;
  public largo:number = 0;
  public ancho:number = 0;
  public alto:number = 0;
  public paleta:number = 0;
  public isotermico:number = 0;
  public jaulaCantidad:number = 0;
  public frigorifico:number = 0;
  public restante:number = 0;
  public cubitaje:number = 0;
  public porcentaje_ocupado:number = 0;
  public OcupacionVehiculo:Array<Ocupacion> = [];
  public alertOcupacion = false;
  public verBoton:boolean = true;



  constructor(private sharingService:SharingService,
              private formGroupDirective: FormGroupDirective,
              private servicesService:ServicesService)
  {
    this.vehiculo$ = this.sharingService.getTipoTransporte;
    this.vehiculoSubscription = this.vehiculo$.subscribe(resp=>{
      this.tipoCarga = resp.tipo_carga;
      this.cubitaje = resp.cubitaje;
    })
  }


  ngOnInit(): void {
    this.enviar = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    let arreglo = this.enviar.get('articulos') as FormArray;

    if(arreglo.length == 0){
      switch (this.tipoCarga){
        case 1: this.addCargaLiquida(); break;
        case 2: this.addPaletizados(); break;
        case 3: this.addFrigorificoCalorifico(); break;
        case 4: this.addTolva(); break;
        case 5: this.addJaula(); break;
        case 6: this.addEspeciales(); break;
      }

      this.sharingService.step = this.sharingService.step + 1;
    }
    else
    { this.sharingService.step = this.sharingService.step + 1;

      if(this.sharingService.step == 2){
        arreglo.clear();
        switch (this.tipoCarga){
          case 1: this.addCargaLiquida(); break;
          case 2: this.addPaletizados(); break;
          case 3: this.addFrigorificoCalorifico(); break;
          case 4: this.addTolva(); break;
          case 5: this.addJaula(); break;
          case 6: this.addEspeciales(); break;
        }
      }
    }

    this.getOcupacionVehiculo();
  }


  cerrar(indice:number){
    this.verBoton = true;
    (<FormArray>this.enviar.get('articulos')).removeAt(indice)
    this.recalc();
  }


  addJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    this.jaulaCantidad++
    doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
  }


  minusJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    if(this.jaulaCantidad > 0){
      this.jaulaCantidad--;
      doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
    }
  }


  addFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    this.frigorifico++;
    doc.get('cant_articulos').setValue(this.frigorifico);
  }


  minusFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    if(this.frigorifico > 0){
      this.frigorifico--;
      doc.get('cant_articulos').setValue(this.frigorifico);
    }
  }


  addCantidad(indice:number){
    let articulos = this.enviar.get('articulos') as FormArray;
    let art = articulos.controls[indice] as FormGroup;
    let newCant = parseInt(art.get('canti_art').value)+1;
    let result = this.calcOcupacion(indice, newCant);
    if(result<=100 && result>0){
      this.porcentaje_ocupado = result;
      art.get('canti_art').setValue(newCant);
      this.alertOcupacion = false;
    }else if(result==0){
      this.alertOcupacion = true;
    }
  }


  minusCantidad(indice:number){
    let articulos = this.enviar.get('articulos') as FormArray;
    let art = articulos.controls[indice] as FormGroup;
    let newCant = parseInt(art.get('canti_art').value)-1;
    let result = this.calcOcupacion(indice, newCant);
    if(result<=100 && result>0 && newCant>0){
      this.porcentaje_ocupado = result;
      art.get('canti_art').setValue(newCant);
      this.alertOcupacion = false;
    }
  }


  recalc(){
    let result = this.calcOcupacion();
    if(result<=100 && result>0){
      this.porcentaje_ocupado = result;
      this.alertOcupacion = false;
    }else{
      this.alertOcupacion = true;
    }
  }


  calcOcupacion(indice:number=null,cantidad:number=null){
    let articulos = this.enviar.get('articulos') as FormArray;
    let area = 0;
    let fail = false
    articulos.controls.forEach((articulo,idx) => {

      const[anch,lar,alt,cant,apilab,peso_artic] = [parseInt(articulo.value['anch']),
                              parseInt(articulo.value['lar']),
                              parseInt(articulo.value['alt']),
                              parseInt(articulo.value['canti_art']),
                              (articulo.value['cantidad_apilable'])?articulo.value['cantidad_apilable']:1,
                              parseInt(articulo.value['peso_artic'])];

      if(!fail && this.checkValue(peso_artic) &&
         this.checkValue(anch) &&
         this.checkValue(lar) &&
         this.checkValue(alt) &&
         alt <= 240 &&
         (apilab * alt) <= this.sharingService.alto){
        area = area + (anch * lar *
              (Math.ceil((idx == indice?cantidad:cant)/apilab)));
      }else{
        fail = true;
      }
    });

    return (!fail)?((area * 100) / this.sharingService.area) : 0;

  }

  checkValue(value){
    return !isNaN(value) && value > 0;
  }

  fileFotodeCarga(event,indice:number){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[indice] as FormGroup;
      doc.get('foto_de_carga').setValue(event.target.files[0]);
      doc.get('name_foto_de_carga').setValue(event.target.files[0].name);
    }
  }


  addFotoCarga(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('foto_carga').setValue(event.target.files[0]);
      doc.get('name_foto_carga').setValue(event.target.files[0].name);
    }
  }


  fileFotoCarga(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('fotocarga').setValue(event.target.files[0]);
      doc.get('nameFotocarga').setValue(event.target.files[0].name);
    }
  }


  addFoto(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addFoto').setValue(event.target.files[0]);
      doc.get('nombreAddFoto').setValue(event.target.files[0].name);
    }
  }


  fileaddDocumento(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addDocumento').setValue(event.target.files[0]);
      doc.get('nameAddDocumento').setValue(event.target.files[0].name);
    }
  }


  addEURPallet1(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(80);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(1);
    this.recalc();
  }


  addEURPallet3(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(100);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(2);
    this.recalc();
  }


  addEURPallet6(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(80);
    doc.get('anch').setValue(60);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(3);
    this.recalc();
  }


  cajaMudanza(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(75);
    doc.get('anch').setValue(35);
    doc.get('alt').setValue(35);
    doc.get('seleccionPaleta').setValue(4);
    this.recalc();
  }


  paletaSeleccionada(indice:number){
    let paleta = { value: 0 };
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    paleta.value = doc.controls['seleccionPaleta'].value
    return paleta
  }


  addContenedorLiquido(){
    if((<FormArray>this.enviar.get('articulos')).length < 4){
      this.addCargaLiquida();
    }

    if((<FormArray>this.enviar.get('articulos')).length == 4){
      this.verBoton = false;
    }
  }


  addCargaLiquida():void {
    (<FormArray>this.enviar.get('articulos')).push(new FormGroup({
        volumen: new FormControl('',[Validators.required]),
        unidad_medida: new FormControl(0,[Validators.required, Validators.min(1)]),
        descripcion: new FormControl('',[Validators.required]),
        isotermico: new FormControl(false),
        precinto: new FormControl(false),
    }));
  }


  addPaletizados():void {
    (<FormArray>this.enviar.get('articulos')).push(new FormGroup({
        peso_artic: new FormControl('',[Validators.required, Validators.min(1)]),
        lar: new FormControl('',[Validators.required, Validators.min(1)]),
        anch: new FormControl('',[Validators.required, Validators.min(1)]),
        alt: new FormControl('',[Validators.required, Validators.min(1)]),
        canti_art: new FormControl(1,[Validators.required, Validators.min(1)]),
        paletizado: new FormControl(false),
        noapilable: new FormControl(false),
        cantidad_apilable: new FormControl({value: 0, disabled: true},[Validators.required]),
        restante: new FormControl(0),
        foto_de_carga: new FormControl(''),
        name_foto_de_carga: new FormControl(''),
        seleccionPaleta: new FormControl(0),
    }));
    this.recalc();
  }


  addFrigorificoCalorifico():void {
    (<FormArray>this.enviar.get('articulos')).push(new FormGroup({
      peso_art: new FormControl('',[Validators.required]),
      largo: new FormControl('',[Validators.required]),
      ancho: new FormControl('',[Validators.required]),
      alto: new FormControl('',[Validators.required]),
      cant_articulos: new FormControl(0,[Validators.required, Validators.min(1)]),
      foto_carga: new FormControl(''),
      name_foto_carga: new FormControl(''),
    }));
  }


  addTolva():void {
    (<FormArray>this.enviar.get('articulos')).push(new FormGroup({
        peso_articulo: new FormControl('',[Validators.required]),
        volumen_tolva: new FormControl('',[Validators.required]),
        alimentario: new FormControl(2,[Validators.required]),
        fotocarga: new FormControl(null),
        nameFotocarga: new FormControl(''),
    }));
  }


  //*********************************************************************
  camionJaula():FormGroup {
    return new FormGroup({
      descripcion_jaula: new FormControl('',[Validators.required]),
      cantidad_articulos: new FormControl(0,[Validators.required, Validators.min(1)]),
      addFoto: new FormControl(''),
      nombreAddFoto: new FormControl(''),
    })
  }

  addJaula():void{
    let camion = this.enviar.get('articulos') as FormArray;
    camion.push(this.camionJaula());
  }
  //*********************************************************************


  camionEspeciales():FormGroup {
    return new FormGroup({
        descripcion_especiales: new FormControl('',[Validators.required]),
        addDocumento: new FormControl(null),
        nameAddDocumento: new FormControl(''),
    });
  }


  addEspeciales():void {
    let camion = this.enviar.get('articulos') as FormArray;
    camion.push(this.camionEspeciales());
  }
//*********************************************************************


  invalidate(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateCantidad(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }


  invalidateSelect(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }


  invalidatePaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateCantidadPaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }


  //*********************************************************************

  sumacubitaje(indice:number){
    let articulos = this.enviar.get('articulos') as FormArray;
    let doc = articulos.controls[indice] as FormGroup;

    if(doc.controls['noapilable'].value){
      //Apilable
      const caja = this.getVolumenCaja(indice) * doc.controls['canti_art'].value;
      const espacio = this.OcupacionVehiculo[0].largo * this.OcupacionVehiculo[0].ancho * (doc.controls['alt'].value * doc.controls['cantidad_apilable'].value)
      let porcentaje = this.reglaDeTres(espacio,caja);
      this.sharingService.porcentaje_total = porcentaje;
    }
    else
    { //No Apilable
      const caja = this.getAreaCaja(indice) * doc.controls['canti_art'].value;
      const espacio = this.OcupacionVehiculo[0].superficie
      let porcentaje = this.reglaDeTres(espacio,caja);
      this.sharingService.porcentaje_total = porcentaje;
    }
    this.porcentaje_ocupado = this.sharingService.porcentaje_total;
  }


  restacubitaje(indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    if(doc.controls['noapilable'].value){
      const caja = this.getVolumenCaja(indice) * doc.controls['canti_art'].value;
      const espacio = this.OcupacionVehiculo[0].largo * this.OcupacionVehiculo[0].ancho * (doc.controls['alt'].value * doc.controls['cantidad_apilable'].value)
    }
    else
    {
      const caja = this.getAreaCaja(indice) * doc.controls['canti_art'].value;
      const espacio = this.OcupacionVehiculo[0].superficie
      let porcentaje = this.reglaDeTres(espacio,caja);
      this.sharingService.porcentaje_total = this.sharingService.porcentaje_total + porcentaje;
    }
    this.porcentaje_ocupado = this.sharingService.porcentaje_total;
  }


  getVolumenCaja(indice:number):number{
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;
    let valor:number = 0;

    if(doc.controls['lar'].value > 0 && doc.controls['anch'].value > 0 && doc.controls['alt'].value > 0 && doc.controls['alt'].value <= 240)
    {
      valor = doc.controls['lar'].value * doc.controls['anch'].value * doc.controls['alt'].value
    }
    return valor;
  }

  getAreaCaja(indice:number):number{
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;
    let valor:number = 0;

    if(doc.controls['lar'].value > 0 && doc.controls['anch'].value > 0 && doc.controls['alt'].value > 0 && doc.controls['alt'].value <= 240)
    {
      valor = doc.controls['lar'].value * doc.controls['anch'].value
    }
    return valor;
  }

  reglaDeTres(espacio:number,caja:number):number{
    let valor:number = 0;
      valor = (caja * 100) / espacio;
    return valor;
  }


  apilable(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    let valor = doc.get('noapilable').value

    if(doc.get['cantidad_apilable'])doc.get['cantidad_apilable'].setValue(0);
    if(valor == true){
      doc.get('cantidad_apilable').disable();
    }
    else
    { doc.get('cantidad_apilable').enable(); }

    this.recalc();
  }

  getOcupacionVehiculo(){

    let frigorifico: Ocupacion;
    frigorifico = new Ocupacion('frigorifico',1331,248,260,330088,85822880);

    let calorifico: Ocupacion;
    calorifico = new Ocupacion('calorifico',1331,248,260,330088,85822880);

    this.OcupacionVehiculo.push(frigorifico);
    this.OcupacionVehiculo.push(calorifico);

    console.log('OcupacionVehiculo',this.OcupacionVehiculo);
  }

  //*********************************************************************
  ngOnDestroy(): void {
    this.vehiculoSubscription.unsubscribe();
  }

  numericOnly(event):boolean{
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
}
