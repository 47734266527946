<ng-container [formGroup]="trayecto">
  <div class="form-group row">
    <div class="card">
      <div class="card-body">

        <div class="row mt-2">
          <div class="col-sm-6">
            <label style="color: #000000;"><b>Origen</b></label>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <label style="color: #000000;">Provincia <span style="color: red;">*</span></label>
            <input type="text"
                  placeholder="Provincia"
                  class="form-control"
                  formControlName="origen_provincia"
                  autocomplete="off"
                  style="font-size: 12px; margin-top: -5px;"
                  [ngClass]="{'is-invalid': invalidate('origen_provincia').value }">
          </div>

          <div class="col-sm-3">
            <label style="color: #000000;">Ciudad <span style="color: red;">*</span></label>
            <input type="text"
                  placeholder="Ciudad"
                  class="form-control"
                  formControlName="origen_ciudad"
                  autocomplete="off"
                  style="font-size: 12px; margin-top: -5px;"
                  [ngClass]="{'is-invalid': invalidate('origen_ciudad').value }">
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Calle <span style="color: red;">*</span></label>
            <input type="text"
                   placeholder="Calle"
                   class="form-control"
                   formControlName="origen_calle"
                   autocomplete="off"
                   style="font-size: 12px; margin-top: -5px;"
                   [ngClass]="{'is-invalid': invalidate('origen_calle').value }">
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Número</label>
            <input type="text"
                   placeholder="Número"
                   class="form-control"
                   formControlName="origen_numero"
                   autocomplete="off"
                   style="font-size: 12px; margin-top: -5px;"
                   >
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Código Postal <span style="color: red;">*</span></label>
            <input type="number"
                  placeholder="Código Postal"
                  class="form-control"
                  formControlName="origen_cod_postal"
                  autocomplete="off"
                  style="font-size: 12px; margin-top: -5px;"
                  [ngClass]="{'is-invalid': invalidate('origen_cod_postal').value }">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-sm-6">
            <label style="color: #000000;"><b>Destino</b></label>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <label style="color: #000000;">Provincia <span style="color: red;">*</span></label>
            <input type="text"
                  placeholder="Provincia"
                  class="form-control"
                  formControlName="destino_provincia"
                  style="font-size: 12px; margin-top: -5px;"
                  autocomplete="off"
                  [ngClass]="{'is-invalid': invalidate('destino_provincia').value }">
          </div>

          <div class="col-sm-3">
            <label style="color: #000000;">Ciudad <span style="color: red;">*</span></label>
            <input type="text"
                  placeholder="Ciudad"
                  class="form-control"
                  formControlName="destino_ciudad"
                  autocomplete="off"
                  style="font-size: 12px; margin-top: -5px;"
                  [ngClass]="{'is-invalid': invalidate('destino_ciudad').value }">
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Calle <span style="color: red;">*</span></label>
            <input type="text"
                   placeholder="Calle"
                   class="form-control"
                   formControlName="destino_calle"
                   autocomplete="off"
                   style="font-size: 12px; margin-top: -5px;"
                   [ngClass]="{'is-invalid': invalidate('destino_calle').value }">
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Número</label>
            <input type="text"
                   placeholder="Número"
                   class="form-control"
                   formControlName="destino_numero"
                   autocomplete="off"
                   style="font-size: 12px; margin-top: -5px;"
                   >
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Código Postal <span style="color: red;">*</span></label>
            <input type="number"
                  placeholder="Código Postal"
                  class="form-control"
                  formControlName="destino_cod_postal"
                  autocomplete="off"
                  style="font-size: 12px; margin-top: -5px;"
                  (keyup)="zonaPostal($event)"
                  [ngClass]="{'is-invalid': invalidate('destino_cod_postal').value }">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">Fecha máxima de entrega</label>
              <input type="date"
                      class="form-control"
                      name="fecha_maxima"
                      formControlName="fecha_maxima"
                      autocomplete="off"
                      style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>

          <div class="col-sm-4">
            <div>
              <label style="color: black;">Orden de carga</label>
            </div>
            <div>
              <button class="contenedor-btn-file" (click)="fileID.click()">
                <img src="../../../assets/images/transfriendly/upload.png">
                  Agregar documento
                <label></label>
                <input type="file"
                        id="fileID"
                        #fileID
                        class="form-control"
                        (change)="onFileChange($event)"
                        style="font-size: 12px; margin-top: -5px;">
              </button>
            </div>
          </div>

          <div class="col-sm-2">
            <label style="color: #000000;">Nombre Archivo</label>
            <input type="text"
                   placeholder="Nombre archivo"
                   class="form-control"
                   formControlName="orden_entrega_name"
                   autocomplete="off"
                   style="font-size: 12px; margin-top: -5px;">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" style="color: black;">Descripción</label>
              <textarea class="form-control"
                        id="exampleFormControlTextarea1"
                        autocomplete="off"
                        rows="2"
                        formControlName="descripcion"></textarea>
            </div>
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">¿Quien recibe en el destino? <span style="color: red;">*</span></label>
              <select class="form-select"
                      aria-label="Default select example"
                      style="margin-top: -5px;"
                      formControlName="quien_recibe"
                      (change)='getQuienrecibe()'
                      [ngClass]="{'is-invalid': invalidateQuienRecibe('quien_recibe').value }">
                <option value="-1" [disabled]="true">¿Quien recibe?</option>
                <option value="9998">Expedidor Desconocido</option>
                <option value="9999">Expedidor no Registrado</option>
                <option value="{{ item.id }}" *ngFor="let item of descargadores;let i = index">
                  {{ item.name }} ({{ item.direccion_descarga }})
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <label style="color: #000000;">El punto de descarga cuenta con una bomba de velocidad...</label>
            </div>

            <div class="form-check a">
              <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      autocomplete="off"
                      formControlName="muelle">
              <label class="form-check-label" style="color: #000000; font-size: 10px;">
                Baja
              </label>
            </div>

            <div class="form-check a">
              <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      autocomplete="off"
                      formControlName="transpaleta">
              <label class="form-check-label" style="color: #000000; font-size: 10px;">
                Media
              </label>
            </div>

            <div class="form-check a">
              <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      autocomplete="off"
                      formControlName="carretilla">
              <label class="form-check-label" style="color: #000000; font-size: 10px;">
                Rápida
              </label>
            </div>
          </div>
        </div>


        <div class="row mt-4" *ngIf="verQuien">
          <label style="color: #000000; font-size: 14px;"><b>Datos para registrar expedidor</b></label>
        </div>

        <div class="row mt" *ngIf="verQuien">
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">Nombre <span style="color: red;">*</span></label>
              <input type="text"
                     placeholder="Nombre"
                     class="form-control"
                     formControlName="nombre"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;"
                     [ngClass]="{'is-invalid': invalidate('nombre').value }">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">NIF/CIF</label>
              <input type="text"
                     placeholder="NIF/CIF"
                     class="form-control"
                     formControlName="dni"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>
        </div>

        <div class="row mt-2" *ngIf="verQuien">
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">Teléfono <span style="color: red;">*</span></label>
              <input type="text"
                     placeholder="Teléfono"
                     class="form-control"
                     formControlName="telefono"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;"
                     [ngClass]="{'is-invalid': invalidate('telefono').value }">
            </div>
            <!--
            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                Guardar como origen recurrente
              </label>
            </div>
            -->
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label style="color: #000000;">Correo</label>
              <input type="text"
                     placeholder="Correo"
                     class="form-control"
                     formControlName="correo"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
