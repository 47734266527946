<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <div>
    <a class="navbar-brand">
      <span style="color: rgb(240, 240, 240);">Logo</span>
    </a>
  </div>

  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor03"
          aria-controls="navbarColor03"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="isCollapsed = !isCollapsed">
          <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end"
       id="navbarColor03"
       [ngbCollapse]="isCollapsed">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <div class="dropdown d-lg-inline-block me-1">
          <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="verAddTransportista()">
            <i class="bx bxl-telegram"></i>
          </button>
        </div>
      </li>

      <li class="nav-item">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
            ngbDropdownToggle>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">3</span>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
               aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col-auto">
                  <a href="javascript: void(0);" class="small">Notificacion 1</a>
                </div>
              </div>
            </div>

            <div class="p-3">
              <div class="row align-items-center">
                <div class="col-auto">
                  <a href="javascript: void(0);" class="small">Notificacion 2</a>
                </div>
              </div>
            </div>

            <div class="p-3">
              <div class="row align-items-center">
                <div class="col-auto">
                  <a href="javascript: void(0);" class="small">Notificacion 3</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
            <span class="d-xl-inline-block ms-1">Maria Velez</span>
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </button>
          <div ngbDropdownMenu pull-right style="left: -75px;">
            <button ngbDropdownItem>Configuración de la cuenta </button>
            <div ngbDropdownItem>
              <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckChecked">Notificaciones</label>
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="true">
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <div ngbDropdownItem>
              <div>
                <img src="assets/images/transfriendly/truck-2.png" style="height: 20px; width: 20px; margin-right: 10px;">
                <label>Transportista</label>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem (click)="logout()">
              Cerrar sesión
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
