import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-transportista',
  templateUrl: './add-transportista.component.html',
  styleUrls: ['./add-transportista.component.scss']
})
export class AddTransportistaComponent {

  public documentacion: FormGroup;
  public seguroSeleccionado:boolean = false;
  public certificadoSeleccionado:boolean = false;



  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.documentacion.get(nameFormControl)?.touched ||
        this.documentacion.get(nameFormControl)?.dirty) &&
        this.documentacion.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  onFileChange(event){
    this.documentacion.controls['certificadoName'].setValue(event.target.files[0].name);
    this.documentacion.controls['certificado'].setValue(event.target.files[0]);
    this.certificadoSeleccionado = true;
  }

  onFileChangeSeguro(event){
    this.documentacion.controls['seguroName'].setValue(event.target.files[0].name);
    this.documentacion.controls['seguro'].setValue(event.target.files[0]);
    this.seguroSeleccionado = true;
  }

}
