<div class="row">
  <div class="col-12">
      <div class="card ">
          <div class="card-body border-bottom">
              <div>
                <form [formGroup]="searchForm"  class="d-xl-block">
                  <div class="filters">
                    <div class="buscar">
                      <div class="app-search position-relative">
                        <input type="search"
                              class="form-control"
                              placeholder="Código anuncio"
                              formControlName="codigo_anuncio">
                        <span class="bx bx-search-alt"></span>
                      </div>
                    </div>
                    <input type="search" class="origen form-control" formControlName="origen" placeholder="Origen">
                    <input type="search" class="destino form-control" formControlName="destino" placeholder="Destino">
                    <input type="text" onkeydown="return false"
                            class="desde form-control" placeholder="Desde"
                            onfocus="(this.type='date')"
                            onblur="(this.type= this.value ? 'date' : 'text')"
                            formControlName="desde">
                    <div class="error errDesde" *ngIf="f.desde.errors?.required">Es obligatorio el rango completo de fechas</div>
                    <div class="error errDesde" *ngIf="f.desde.errors?.rango">La fecha de inicio no puede ser superior a la fecha final</div>
                    <input type="text" onkeydown="return false"
                            class="hasta form-control" placeholder="Hasta"
                            onfocus="(this.type='date')"
                            onblur="(this.type= this.value ? 'date' : 'text')"
                            formControlName="hasta">

                    <button type="button" class="exportar btn btn-secondary" (click)="exportexcel()">
                      Exportar
                    </button>

                    <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid" (click)="search()">
                      <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                      Filtrar
                    </button>

                  </div>
                  </form>
              </div>
          </div>

          <div class="card-body">
            <ul>
              <li class="item-work"
                  *ngFor="let row of listWorkDone"
                  (click)="viewPayment(row)">
                <div>
                  <div class="route">Viaje {{row.ciudad_origen}} - {{row.ciudad_destino}}</div>
                  <div class="date">Fecha: {{row.fechasalida | date:'dd/MMM/yyyy'}}</div>
                </div>
                <!-- <div style="text-align: center;">
                  <div>Estado del pago</div>
                  <span [ngClass]="{'R':'statePay ok','P':'statePay pending'}[row.idPaymentStatus]">
                    {{row.paymentStatus}}</span>
                </div> -->
                <div style="text-align: right;">
                  <div class="payment">
                    <img
                      src="{{(row.descripcion == 'Transferencia')?
                              'assets/images/transfriendly/transfer.png':
                              'assets/images/transfriendly/card.png'}}">
                    <span>Pago: {{row.descripcion}}</span></div>
                  <div class="amount">{{row.valor | formatNumberPipe }}€/Tn</div>
                </div>
              </li>
            </ul>
          </div>

          <mat-paginator [length]="dataSource.length"
                [pageSize]="size"
                [pageIndex]="page"
                [pageSizeOptions]="[5,10, 20, 30]"
                (page)="linkListToPaginator($event)">
          </mat-paginator>

        </div>
      </div>
  </div>
