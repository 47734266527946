
<mat-dialog-content style="width: 550px;" *ngIf="mode === 'view'">
    <form [formGroup]="pujaForm">
        <div class="card head">
            <b style = "font-size: 1.8em; margin-bottom: 7px;">
                {{dataDialog.ciudad_origen}} - {{dataDialog.ciudad_destino}}
            </b>
        </div>
        <label><b>Puja</b></label>
        <input type="text"
               class="form-control"
               placeholder="0,00"
               formControlName="valor"
               currencyMask
               [options]="{ prefix:'', suffix:' €', thousands:'.', precision:2, decimal:',', allowNegative:false }"
               >
        <div class="error" *ngIf="f.valor.errors?.required">El Costo de la puja es un dato obligatorio</div>

        <label><b>Comentarios (opcional)</b></label>
        <textarea class="form-control" rows="4" formControlName="comentario"></textarea>
        <label><b>Fecha tentativa a realizar el viaje (Opcional)</b></label>
        <input type="date"
               onkeydown="return false"
               class="form-control"
               placeholder="Fecha tentativa"
               formControlName="fecha_viaje">
               <div class="error" *ngIf="f.tipo_vehiculo.errors?.required">Debe seleccionar un tipo de vehículo</div>
        <div class="card vehicles">
            <table>
              <tr *ngFor="let vehicle of dataDialog.listTypeVehicles"
                (click)="selTypeVehicle(vehicle.idtipotransporte)"
                [ngClass]="{'selected':f.tipo_vehiculo.value === vehicle.idtipotransporte}">
                <td class="image">
                  <img src="{{vehicle.url}}" >
                </td>
                <td>
                  <label>{{vehicle.nombre}}</label>
                  <div class="data">
                    <span>Hasta {{vehicle.carga_util}} : {{vehicle.descripcion}} </span><br>
                  </div>
                </td>
              </tr>
            </table>
        </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions *ngIf="mode === 'view'">
    <div class="col-12">
        <div class="row g-3">
            <div class="col-sm-6">
                <button type="button" class="btn btn-secondary col-sm-7" (click)="close()">
                    Cancelar
                </button>
            </div>
            <div class="col-sm-6" style = "text-align: right;" (click)="send()">
                <button type="button"
                        class="btn btn-primary col-sm-7"
                        [disabled]="!pujaForm.valid">
                    Enviar
                </button>
            </div>
        </div>
    </div>
</mat-dialog-actions>


<mat-dialog-content style="width: 550px; margin-top: 2em;" *ngIf="mode === 'sended'">
  <div style="text-align: center;">
    <span>
      <img style="padding-left: 2em;" src="assets/images/transfriendly/fileOk.png" >
    </span>
    <div style="font-size: 1.5em;color: black;margin-top: 30px;">
      ¡Listo! Su puja ha sido {{(dataDialog.idpuja)?'modificada':'enviada'}}
    </div>
  </div>
</mat-dialog-content>


<mat-dialog-actions *ngIf="mode === 'sended'" style="margin-bottom: 2em;">
    <div class="col-12">
        <div class="row g-3">
            <div class="col-sm-12" style = "text-align: center;" >
                <button type="button" class="btn btn-primary col-sm-5" (click)="closeSended()">
                    Aceptar
                </button>
            </div>
        </div>
    </div>
</mat-dialog-actions>
