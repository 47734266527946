import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-articulos-editar',
  templateUrl: './articulos-editar.component.html',
  styleUrls: ['./articulos-editar.component.scss']
})

export class ArticulosEditarComponent implements OnInit {
  @Input() formGroupName: string;

  public enviar: FormGroup;
  public tipoCarga:number;
  public jaulaCantidad:number = 0;
  public frigorifico:number = 0;
  public cantidad:number;
  public porcentaje_ocupado:number = 0;

  constructor( private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.enviar = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.tipoCarga = this.enviar.get('tipo_carga').value
  }

  addFoto(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addFoto').setValue(event.target.files[0]);
      doc.get('nombreAddFoto').setValue(event.target.files[0].name);
    }
  }

  invalidateCantidad(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }

  invalidate(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  fileFotoCarga($event){}

  fileaddDocumento(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addDocumento').setValue(event.target.files[0]);
      doc.get('nameAddDocumento').setValue(event.target.files[0].name);
    }
  }

  addFotoCarga(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('foto_carga').setValue(event.target.files[0]);
      doc.get('name_foto_carga').setValue(event.target.files[0].name);
    }
  }


  addFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    this.frigorifico++;
    doc.get('cant_articulos').setValue(this.frigorifico);
  }


  minusFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    if(this.frigorifico > 0){
      this.frigorifico--;
      doc.get('cant_articulos').setValue(this.frigorifico);
    }
  }

  //******************************************



  cerrar(indice:number){
    (<FormArray>this.enviar.get('articulos')).removeAt(indice)
  }


  addJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    this.jaulaCantidad++
    doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
  }


  minusJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    if(this.jaulaCantidad > 0){
      this.jaulaCantidad--;
      doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
    }
  }





  addCantidad(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    this.cantidad = doc.get('canti_art').value;
    this.cantidad++;
    doc.get('canti_art').setValue(this.cantidad);

  }


  minusCantidad(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    this.cantidad = doc.get('canti_art').value;
    if(this.cantidad > 0){
      this.cantidad--;
      doc.get('canti_art').setValue(this.cantidad);

    }
  }


  fileFotodeCarga(event,indice:number){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[indice] as FormGroup;
      doc.get('foto_de_carga').setValue(event.target.files[0]);
      doc.get('name_foto_de_carga').setValue(event.target.files[0].name);
    }
  }


  addEURPallet1(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(80);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(1);
  }


  addEURPallet3(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(100);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(2);
  }


  addEURPallet6(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(80);
    doc.get('anch').setValue(60);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(3);
  }


  cajaMudanza(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(75);
    doc.get('anch').setValue(35);
    doc.get('alt').setValue(35);
    doc.get('seleccionPaleta').setValue(4);
  }


  paletaSeleccionada(indice:number){
    let paleta = { value: 0 };
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    paleta.value = doc.controls['seleccionPaleta'].value
    return paleta
  }


//*********************************************************************
//*********************************************************************
//*********************************************************************

  invalidateSelect(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }


  invalidatePaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateCantidadPaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }

  //*********************************************************************

  apilable(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    let valor = doc.get('noapilable').value
    if(valor == true){
      doc.get('cantidad_apilable').disable();
    }
    else
    { doc.get('cantidad_apilable').enable(); }
  }

  //*********************************************************************

  addContenedorLiquido(){}

}
