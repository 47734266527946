<div class="mt-2">
  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-4 text-center">
              <img src="assets/images/transfriendly/charger-dos.png" style="width: 60%">
            </div>
            <div class="col-sm-8">
              <label style="display: flex; flex-direction: column; font-size: 32px;"><b>¡Hola!</b></label>
              <label style="display: flex; flex-direction: column; font-size: 22px; margin-top: -15px;"><b>{{ registerUser.alias }}</b></label>
              <button class="btn btn-primary" style="display: flex; flex-direction: column;">
                Destinatario
              </button>
            </div>
          </div>
        </div>

        <div class="col-sm-6" style="margin-top: 30px;">
            <div class="row text-center">
              <div class="col-sm-4">
                <label style="display: flex; flex-direction: column; font-size: 38px;"><b>{{propuestas}}</b></label>
                <label style="display: flex; flex-direction: column; font-size: 12px; margin-top: -10px;"><b>Propuestas</b></label>
              </div>
              <div class="col-sm-4">
                <label style="display: flex; flex-direction: column; font-size: 38px;"><b>{{negociacion}}</b></label>
                <label style="display: flex; flex-direction: column; font-size: 12px; margin-top: -10px;"><b>En negociación</b></label>
              </div>
              <div class="col-sm-4">
                <label style="display: flex; flex-direction: column; font-size: 38px;"><b>{{contratos}}</b></label>
                <label style="display: flex; flex-direction: column; font-size: 12px; margin-top: -10px;"><b>Contratos</b></label>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
