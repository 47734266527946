import { Component } from '@angular/core';

@Component({
  selector: 'app-cargador-principal',
  templateUrl: './cargador-principal.component.html',
  styleUrls: ['./cargador-principal.component.scss']
})
export class CargadorPrincipalComponent {

  active = 1;
  vMessage:any=null;

  ngOnInit(){
    this.vMessage = JSON.parse(localStorage.getItem('goMsg'));
    if(this.vMessage) this.active = 3;
  }

}
