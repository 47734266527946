<ng-container>
  <div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">

    <div class="logo-container">
      <button class="logo" (click)="toggleCollapse()">
        <img src="assets/images/Ewine/Circular_Trans_Logo.png" alt="" class="img-fluid">
      </button>

      <div class="logo-text" @fadeInOut *ngIf="collapsed" style="font-size: 18px;">CircularTrans</div>

      <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSidenav()">
        <i class="fal fa-times close-icon"></i>
      </button>
    </div>


    <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngFor="let data of navData">
            <a *ngIf="data.routeLink" class="sidenav-nav-link"
               [routerLink]="[data.routeLink]"
               href="data.href"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}">
               <i class="sidenav-link-icon" [class]="data.icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    {{data.label}}
                </span>
            </a>
            <a *ngIf="data.href" class="sidenav-nav-link"
               href="{{data.href}}" target="_blank">
               <i class="sidenav-link-icon" [class]="data.icon"></i>
                <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
                    {{data.label}}
                </span>
            </a>
        </li>
    </ul>
</div>
</ng-container>

