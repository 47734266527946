import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})

export class PersonalInformationComponent implements OnInit  {

  @Input() formGroupName: string;
  public informationForm: FormGroup;

  constructor(public languageService: LanguageService, private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.informationForm = this.formGroupDirective.control.get(this.formGroupName) as FormGroup
  }

  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.informationForm.get(nameFormControl)?.touched ||
        this.informationForm.get(nameFormControl)?.dirty) &&
        this.informationForm.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  invalidateCorreo2(nameFormControl: string){
    let valida = { value: false };
    if((this.informationForm.get(nameFormControl)?.touched ||
        this.informationForm.get(nameFormControl)?.dirty) &&
        this.informationForm.get(nameFormControl)?.invalid ||
        this.informationForm.get('correo')?.value != this.informationForm.get('correoConfirmar')?.value)
    { valida.value = true; }

    return valida
  }

}
