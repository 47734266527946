<div class="row mt-2">
  <div class="col-sm-12">
     <div class="text-center">
       <label style="color: black;">
        Adjunte las fotos de su documento de identidad, tanto por el frente como por el reverso.
      </label>
     </div>
  </div>
</div>


<div class="row mt-2">
  <div class="col-sm-6">
     <div class="text-center">
       <label style="color: black;">Frente</label>
     </div>

     <div class="text-center">
      <div class="container">
        <div class="wrapper">
          <div class="image">
            <img src="" alt="">
          </div>
          <div class="content">
            <div class="icon"><i class="fas fa-cloud-upload-alt"></i></div>
            <div class="text">¡Todavía no se ha elegido ningún archivo!</div>
          </div>
          <div id="cancel-btn">
            <i class="fas fa-times"></i>
          </div>
        </div>
       </div>

       <div>
        <button class="contenedor-btn-file" (click)="addSelfie()">
          <img src="../../../assets/images/transfriendly/upload.png">
          Agregar Foto
          <label for="btn-file"></label>
          <input type="file" id="btn-file">
        </button>
      </div>
     </div>
  </div>

  <div class="col-sm-6">
    <div class="text-center">
      <label style="color: black;">Reverso</label>
    </div>

    <div class="text-center">
      <div class="container">
        <div class="wrapper">
          <div class="image">
            <img src="" alt="">
          </div>
          <div class="content">
            <div class="icon"><i class="fas fa-cloud-upload-alt"></i></div>
            <div class="text">¡Todavía no se ha elegido ningún archivo!</div>
          </div>
          <div id="cancel-btn">
            <i class="fas fa-times"></i>
          </div>
       </div>
      </div>

     <div>
       <button class="contenedor-btn-file" (click)="addSelfie()">
         <img src="../../../assets/images/transfriendly/upload.png">
         Agregar Foto
         <label for="btn-file"></label>
         <input type="file" id="btn-file">
       </button>
     </div>
    </div>
 </div>
</div>

<div class="row mt-5">
  <div class="col-sm-12">
     <div>
      <label style="color: black;">
        Para verificar la identificación , tome una foto sosteniendo su identificación delante de usted, así como de ve en el ejemplo.
      </label>

      <label style="color: black;">
        * La foto debe mostrarse claramente su cara y su identificación.
      </label>

      <label style="color: black;">
        * La foto debe tomarse con buena luz y ser de buena calidad.
      </label>

      <label style="color: black;">
        * No se permiten fotos con lentes de sol.
      </label>
     </div>
  </div>
</div>


<div class="row mt-2">
  <div class="col-sm-12 text-center">

    <div class="container">
      <div class="wrapper">
        <div class="image">
          <img src="" alt="">
        </div>
        <div class="content">
          <div class="icon"><i class="fas fa-cloud-upload-alt"></i></div>
          <div class="text">¡Todavía no se ha elegido ningún archivo!</div>
        </div>
        <div id="cancel-btn">
          <i class="fas fa-times"></i>
        </div>

      </div>
     </div>

    <div>
      <button class="contenedor-btn-file" (click)="addSelfie()">
        <img src="../../../assets/images/transfriendly/upload.png">
        Agregar Foto
        <label for="btn-file"></label>
        <input type="file" id="btn-file">
      </button>
    </div>
  </div>
</div>
