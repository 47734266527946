import { Component, Inject } from '@angular/core';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';

@Component({
  selector: 'app-dialog-anuncio',
  templateUrl: './dialog-anuncio.component.html',
  styleUrls: ['./dialog-anuncio.component.scss']
})

export class DialogAnuncioComponent {

  dataDialog:any;
  infoAnuncio: any;
  viewPresupuesto:boolean;

  loading=true;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
            private servicesService: ServicesService,
                  private dialogRef: MatDialogRef<DialogAnuncioComponent>,
                   private snackBar: MatSnackBar){
    this.dataDialog = data;
    this.viewPresupuesto = this.dataDialog.viewPresupuesto || false;
  }


  ngOnInit(): void {
    this.loadAnuncio();
  }

  async loadAnuncio(){
    this.loading=true;
    const resp = await this.apiDataAnuncio({idanuncio: this.dataDialog.idanuncio});
    if(resp['code'] == 200){
      this.infoAnuncio = resp['msj'].response;

      //Ajuste cuando no se tiene fechad máxima de entrega
      this.infoAnuncio.trayecto = this.infoAnuncio.trayecto.map((t)=>{
          t.fecha_max_entrega=(t.fecha_max_entrega == '0000-00-00')?null:t.fecha_max_entrega;
          return t;
        })
    }
    else{
      this.snackBar.open(resp['msj'].response,'Ok',{
        duration:3000,
        horizontalPosition:right,
        verticalPosition:bottom,
      });
    }
    this.loading=false;
  }

  apiDataAnuncio(data){
    return new Promise((resolve, reject) => {
      this.servicesService.getDataAnuncio(data).subscribe((resp: any) => {
        resolve(resp)
      },error=>{
        reject(error)
      })
    })
  }

  close(): void {
    this.dialogRef.close();
  }
}
