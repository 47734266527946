<ng-container>
  <div class="row my-4">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Descripción</label>
        <div>{{get('descripcion')}}</div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Fecha de salida</label>
        </div>

        <div>
          {{get('fechasalida') | date:'dd/MM/yyyy'}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Fecha límite de puja</label>
        </div>

        <div>
          {{get('fechamaxpuja') | date:'dd/MM/yyyy'}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Tipo de contrato</label>
        </div>
        <div>
          {{get('tipo_contrato')}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Tipo de Vehiculo</label>
        </div>

        <div>
          {{get('tipo_transporte')}}
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">

    <div class="col-sm-3" *ngIf="viewPresupuesto">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Presupuesto €/Tn</label>
        </div>

        <div>
          {{get('presupuesto') | formatNumberPipe }} €/Tn
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Forma de pago</label>
        </div>

        <div>
          {{get('forma_pago')}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Tiempos de pago</label>
        </div>

        <div>
          {{get('tiempo_pago')}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group">
        <div>
          <label style="color: #000000;">El punto de descarga cuenta con una bomba de velocidad</label>
        </div>

        <div>
          {{get('cuenta_con')}}
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div>
        {{get('material_peligro')== 'NO'? 'El envío NO contiene mercancias peligrosas': 'El envío contiene mercancias peligrosas'}}
      </div>
    </div>
  </div>

</ng-container>

