<div>
  <div class="card">
    <div class="card-body">
      <div class="row mt-2">
        <div class="col-sm-12">
          <div class="text-center">
            <label style="font-size: 18px;"><b>Transportistas sugeridos</b></label>
          </div>

          <table class="table mt-2">
            <tbody>

              <!--
              <tr>
                <td>
                  <img class="rounded-circle header-profile-user"
                       src="assets/images/users/avatar-1.jpg"
                       alt="Header Avatar"
                       style="height: 50px; width: 50px;">
                </td>

                <td>
                  <label style="display: flex; flex-direction: column; font-size: 14px;"><b>Maria Velez</b></label>
                  <div style="margin-top: -7px;">
                    <img src="assets/images/transfriendly/star.png" alt="">
                    <span style="font-size: 12px; color: #4A4A4A;"> 5.0</span>
                    <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span>
                    <span style="font-size: 12px; color: #4A4A4A;"> Más de 100 viajes</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="rounded-circle header-profile-user"
                       src="assets/images/users/avatar-2.jpg"
                       alt="Header Avatar"
                       style="height: 50px; width: 50px;">
                </td>

                <td>
                  <label style="display: flex; flex-direction: column; font-size: 14px;"><b>Santiago Pulido</b></label>
                  <div style="margin-top: -7px;">
                    <img src="assets/images/transfriendly/star.png" alt="">
                    <span style="font-size: 12px; color: #4A4A4A;"> 4.9</span>
                    <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span>
                    <span style="font-size: 12px; color: #4A4A4A;"> Más de 250 viajes</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="rounded-circle header-profile-user"
                       src="assets/images/users/avatar-3.jpg"
                       alt="Header Avatar"
                       style="height: 50px; width: 50px;">
                </td>

                <td>
                  <label style="display: flex; flex-direction: column; font-size: 14px;"><b>Geronimo Pérez</b></label>
                  <div style="margin-top: -7px;">
                    <img src="assets/images/transfriendly/star.png" alt="">
                    <span style="font-size: 12px; color: #4A4A4A;"> 5.0</span>
                    <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span>
                    <span style="font-size: 12px; color: #4A4A4A;"> Más de 300 viajes</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="rounded-circle header-profile-user"
                       src="assets/images/users/avatar-4.jpg"
                       alt="Header Avatar"
                       style="height: 50px; width: 50px;">
                </td>

                <td>
                  <label style="display: flex; flex-direction: column; font-size: 14px;"><b>Marcela Vivas</b></label>
                  <div style="margin-top: -7px;">
                    <img src="assets/images/transfriendly/star.png" alt="">
                    <span style="font-size: 12px; color: #4A4A4A;"> 4.9</span>
                    <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span>
                    <span style="font-size: 12px; color: #4A4A4A;"> Más de 580 viajes</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="rounded-circle header-profile-user"
                       src="assets/images/users/avatar-6.jpg"
                       alt="Header Avatar"
                       style="height: 50px; width: 50px;">
                </td>

                <td>
                  <label style="display: flex; flex-direction: column; font-size: 14px;"><b>Julian Aristizabal</b></label>
                  <div style="margin-top: -7px;">
                    <img src="assets/images/transfriendly/star.png" alt="">
                    <span style="font-size: 12px; color: #4A4A4A;"> 4.9</span>
                    <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span>
                    <span style="font-size: 12px; color: #4A4A4A;"> Más de 100 viajes</span>
                  </div>
                </td>
              </tr>
              -->

            </tbody>
          </table>










        </div>
      </div>
    </div>
  </div>
</div>
