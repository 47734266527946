import { Component } from '@angular/core';

@Component({
  selector: 'app-tabs-descargas',
  templateUrl: './tabs-descargas.component.html',
  styleUrls: ['./tabs-descargas.component.scss']
})
export class TabsDescargasComponent {

}
