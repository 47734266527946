import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  ngOnInit(): void {

    this.webSockets();
  }


  webSockets(){
    // const echo = new Echo({
    //   broadcaster: 'pusher',
    //   cluster: 'us2',
    //   key: environment.keyPusher,
    //   wsHost: window.location.hostname,
    //   wsPort: 6001,
    //   forceTLS: false,
    //   disableStats: true,
    //   enabledTransports: ['ws'],
    // });

    //-- Websockets funcional ---->>

    // const idUser = 100;

    // const options = {
    //   broadcaster: 'pusher',
    //   key: environment.keyPusher,
    //   cluster: 'us2',
    // };

    // const echo = new Echo({
    //   ...options,
    //   wsHost: window.location.hostname,
    //   forceTLS: false,
    //   wsPort: 6001,
    //   client: new Pusher(options.key, options),
    //   disableStats: true,
    //   enabledTransports: ['ws'],
    // });

    // echo.channel(`App.User.${idUser}`)
    // .listen('.prueba',(resp) => {
    // })
  }


}
