import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-administrador',
  templateUrl: './gestion-administrador.component.html',
  styleUrls: ['./gestion-administrador.component.scss']
})
export class GestionAdministradorComponent {

}
