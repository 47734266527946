import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { AuthService } from '../../account/auth/services/auth.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';

@Component({
  selector: 'app-dialog-califica',
  templateUrl: './dialog-califica.component.html',
  styleUrls: ['./dialog-califica.component.scss']
})


export class DialogCalificaComponent implements OnInit {

  public registerUser = { 'id':0, 'name':'', 'perfil':0 , id_anuncio:0};
  public avatar:string;
  public calif1:boolean = false;
  public calif2:boolean = false;
  public calif3:boolean = false;
  public calif4:boolean = false;
  public calif5:boolean = false;
  public valorCalifica:number = 0;
  public comentario:string = '';
  public opciones:string[] = [];
  public lineaUno:string;
  public lineaDos:string;
  public valido:boolean = false;

  public select1:boolean = false;
  public select2:boolean = false;
  public select3:boolean = false;
  public select4:boolean = false;
  public select5:boolean = false;
  public select6:boolean = false;
  public select7:boolean = false;
  public select8:boolean = false;
  public select9:boolean = false;
  public select10:boolean = false;
  public select11:boolean = false;
  public select12:boolean = false;
  public select13:boolean = false;
  public select14:boolean = false;
  public select15:boolean = false;
  public select16:boolean = false;
  public select17:boolean = false;
  public select18:boolean = false;
  public select19:boolean = false;
  public select20:boolean = false;
  public select21:boolean = false;
  public select22:boolean = false;
  public select23:boolean = false;
  public select24:boolean = false;
  public select25:boolean = false;

  public cadena1:string = 'vehiculo en mal estado';
  public cadena2:string = 'No llego en el tiempo acordado';
  public cadena3:string = 'Conducta inapropiada';
  public cadena4:string = 'El vehículo no era el apropiado';
  public cadena5:string = 'La carga no llego en el estado deseado';

  public cadena6:string = 'vehiculo en mal estado';
  public cadena7:string = 'No llego en el tiempo acordado';
  public cadena8:string = 'Conducta inapropiada';
  public cadena9:string = 'El vehículo no era el apropiado';
  public cadena10:string = 'La carga no llego en el estado deseado';

  public cadena11:string = 'vehiculo en mal estado';
  public cadena12:string = 'No llego en el tiempo acordado';
  public cadena13:string = 'Conducta inapropiada';
  public cadena14:string = 'El vehículo no era el apropiado';
  public cadena15:string = 'La carga no llego en el estado deseado';

  public cadena16:string = 'vehiculo en mal estado';
  public cadena17:string = 'No llego en el tiempo acordado';
  public cadena18:string = 'Conducta inapropiada';
  public cadena19:string = 'El vehículo no era el apropiado';
  public cadena20:string = 'La carga no llego en el estado deseado';

  public cadena21:string = 'Excelente servicio';
  public cadena22:string = 'Llego en el tiempo acordado';
  public cadena23:string = 'Trato cordial';
  public cadena24:string = 'Vehículo en excelentes condiciones';
  public cadena25:string = 'La carga llego en perfecto estado';


  constructor(@Inject(MAT_DIALOG_DATA)
              private data: any,
              private authService: AuthService,
              private servicesService:ServicesService,
              private dialogRef: MatDialogRef<DialogCalificaComponent>,
              private snackBar:MatSnackBar){
                this.registerUser = data;
              }

  ngOnInit(): void {
    // this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.refresh(this.registerUser.id, this.registerUser.perfil);

    this.lineaDos = 'Respuesta anónima';
  }

  refresh(id:number, perfil:number){
    this.authService.getProfileUser({idUser:id, idPerfil:perfil}).subscribe((resp:any)=>{
      if(resp.response[0]){
        const {avatar, name} = resp.response[0];
        this.avatar = avatar;
        this.registerUser.name = name || '-';
        this.lineaUno = `¿Cómo fue tu experiencia con ${this.registerUser.name}?`;
      }
    });
  }

  calificar1(){
    this.calif1 = !this.calif1;
    this.calif2 = false;
    this.calif3 = false;
    this.calif4 = false;
    this.calif5 = false;

    if(this.calif1){
      this.valorCalifica = 1;
      this.lineaUno = 'Pésimo';
      this.lineaDos = '';
    }
    else
    { this.valorCalifica = 0;
      this.lineaUno = `¿Cómo fue tu experiencia con ${this.registerUser.name}?`;
      this.lineaDos = 'Respuesta anónima';
    }
  }

  calificar2(){
    this.calif1 = true;
    this.calif2 = !this.calif2;
    this.calif3 = false;
    this.calif4 = false;
    this.calif5 = false;

    if(this.calif2){
      this.valorCalifica = 2;
      this.lineaUno = 'Mal';
      this.lineaDos = '';
    }
    else
    { this.valorCalifica = 1;
      this.lineaUno = 'Pésimo';
      this.lineaDos = '';
    }
  }

  calificar3(){
    this.calif1 = true;
    this.calif2 = true;
    this.calif3 = !this.calif3;
    this.calif4 = false;
    this.calif5 = false;

    if(this.calif3){
      this.valorCalifica = 3;
      this.lineaUno = 'Bastante regular';
      this.lineaDos = '';
    }
    else
    { this.valorCalifica = 2;
      this.lineaUno = 'Mal';
      this.lineaDos = '';
    }
  }

  calificar4(){
    this.calif1 = true;
    this.calif2 = true;
    this.calif3 = true;
    this.calif4 = !this.calif4;
    this.calif5 = false;

    if(this.calif4){
      this.valorCalifica = 4;
      this.lineaUno = 'Muy Bien';
      this.lineaDos = '';
    }
    else
    { this.valorCalifica = 3;
      this.lineaUno = 'Bastante regular';
      this.lineaDos = '';
    }
  }

  calificar5(){
    this.calif1 = true;
    this.calif2 = true;
    this.calif3 = true;
    this.calif4 = true;
    this.calif5 = !this.calif5;

    if(this.calif5){
      this.valorCalifica = 5;
      this.lineaUno = '¡Perfecto!';
      this.lineaDos = '';
    }
    else
    { this.valorCalifica = 4;
      this.lineaUno = 'Muy Bien';
      this.lineaDos = '';
    }
  }

  elegirOpciones(){
    this.opciones = [];
    switch (this.valorCalifica){
      case 1:
              if(this.select1){ this.opciones.push(this.cadena1) }
              if(this.select2){ this.opciones.push(this.cadena2) }
              if(this.select3){ this.opciones.push(this.cadena3) }
              if(this.select4){ this.opciones.push(this.cadena4) }
              if(this.select5){ this.opciones.push(this.cadena5) }
      break;

      case 2:
              if(this.select6){ this.opciones.push(this.cadena6) }
              if(this.select7){ this.opciones.push(this.cadena7) }
              if(this.select8){ this.opciones.push(this.cadena8) }
              if(this.select9){ this.opciones.push(this.cadena9) }
              if(this.select10){ this.opciones.push(this.cadena10) }
      break;

      case 3:
              if(this.select11){ this.opciones.push(this.cadena11) }
              if(this.select12){ this.opciones.push(this.cadena12) }
              if(this.select13){ this.opciones.push(this.cadena13) }
              if(this.select14){ this.opciones.push(this.cadena14) }
              if(this.select15){ this.opciones.push(this.cadena15) }
      break;

      case 4:
              if(this.select16){ this.opciones.push(this.cadena16) }
              if(this.select17){ this.opciones.push(this.cadena17) }
              if(this.select18){ this.opciones.push(this.cadena18) }
              if(this.select19){ this.opciones.push(this.cadena19) }
              if(this.select20){ this.opciones.push(this.cadena20) }
      break;

      case 5:
              if(this.select21){ this.opciones.push(this.cadena21) }
              if(this.select22){ this.opciones.push(this.cadena22) }
              if(this.select23){ this.opciones.push(this.cadena23) }
              if(this.select24){ this.opciones.push(this.cadena24) }
              if(this.select25){ this.opciones.push(this.cadena25) }
      break;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  aceptar(){
    this.elegirOpciones();

    this.servicesService.calificar(this.registerUser.id,this.registerUser.id_anuncio, this.valorCalifica, this.comentario, this.opciones).subscribe(resp =>{
      if(resp.code == 200){
        this.valido = true;
        this.snackBar.open(resp['mensaje'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
      else
      { Swal.fire({ icon: 'error', text: 'Error al registrar calificación, por favor intente mas tarde' }) }
    })
  }
}
