
<div class="container-fluid">
  <div class="mt-3">
    <div class="row">
      <div class="col-sm-6" style="left: 40px;">
        <label style="color: black; font-size: 18px;">
          <b>Tu Panel</b>
        </label>
      </div>

      <div class="col-sm-6" style="text-align:end; right: 40px;">
        <button class="btn btn-primary" (click)="propuesta()">
          Anunciar una propuesta
        </button>
      </div>
    </div>
  </div>

  <app-head-panel></app-head-panel>

  <div class="row">
    <div class="col-sm-9">
      <!--
      <app-cargador-publicaciones></app-cargador-publicaciones>
      -->
      <router-outlet></router-outlet>
    </div>
    <div class="col-sm-3">
      <app-transportista-sugerido></app-transportista-sugerido>
    </div>
  </div>
</div>


