import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class ServicesService {

  constructor(public httpClient:HttpClient){}

  descargadores(idusuario:number, cod_postal?:number):Observable<any>{
    const data = { "idusuario" : idusuario, "cod_postal":cod_postal }
    return this.httpClient.get<any>(`${API_URL}/general/descargadores`, { params: data });
  }

  registerInfoGeneral(data: any):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/addCreate`, data);
  }

  registerArticulo(data: FormData):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/articulos`, data);
  }

  registerTrayecto(data: FormData):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/trayectos`, data);
  }

  validaCorreo(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/validacionesback`, data);
  }
}
