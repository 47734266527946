import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inf-gene',
  templateUrl: './inf-gene.component.html',
  styleUrls: ['./inf-gene.component.scss']
})
export class InfGeneComponent {

  @Input() info_anuncio: string;
  @Input() viewPresupuesto: string;

  constructor(){}

  get(data):any{
    if(this.info_anuncio && this.info_anuncio[data]){
      return this.info_anuncio[data]
    }else{
      return ''
    }
  }

}
