<div class="container-fluid">
  <div class="mt-3">
    <div class="row">
      <div class="col-sm-12" style="left: 40px;">
        <label style="color: black; font-size: 18px;">
          <b>Tu Panel</b>
        </label>
      </div>
    </div>
  </div>


  <app-head-panel-descargador></app-head-panel-descargador>

  <app-tabs-descargas></app-tabs-descargas>
</div>
