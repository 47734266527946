import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';
import { tipoContrato } from '../../core/models/tipoContrato.models';

@Component({
  selector: 'app-info-general',
  templateUrl: './info-general.component.html',
  styleUrls: ['./info-general.component.scss']
})

export class InfoGeneralComponent implements OnInit {

  public tipoTransporte:tipoContrato[];

  @Input() formGroupName: string;
  @Output() eventoOutputHijo = new EventEmitter<string>();
  public informationForm: FormGroup;

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.informationForm = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
  }

  enviarPadre(parametro:any):void{
  	this.eventoOutputHijo.emit(parametro);
  }

  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.informationForm.get(nameFormControl)?.touched ||
        this.informationForm.get(nameFormControl)?.dirty) &&
        this.informationForm.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }
}
