export class Ocupacion {
  nombre:string;
  largo:number;
  ancho:number;
  alto:number;
  superficie:number;
  volumen:number;

  constructor(nombre:string, largo:number, ancho:number, alto:number, superficie:number, volumen:number) {
    this.nombre = nombre;
    this.largo = largo;
    this.ancho = ancho;
    this.alto = alto;
    this.superficie = superficie;
    this.volumen = volumen;
  }
}
