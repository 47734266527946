import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/account/auth/services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCalificaComponent } from '../../../shared/dialog-califica/dialog-califica.component'
import { DialogPerfilComponent } from '../dialog-perfil/dialog-perfil.component';
import { ServicesService } from '../services/services.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})

export class TopBarComponent implements OnInit {

  public isCollapsed = true;
  public registerUser = { 'id':0, 'name':'','alias':'', 'perfil':0 };
  public data = { 'id':0, 'name':'','alias':'', 'perfil':0 }
  public dataPerf:any[] = [];
  public avatar:string;
  public notificacion:any;
  public newNotif:number = 0;
  public newMsg:number = 0;
  public perfil:string = '';
  public perfilesActivos:any[] = [];
  public perfilesPorConfirmar:any[] = [];
  public perfilesPuedeSolicitar:any[] = [];
  public perf:number = 0;
  private viewNotif:boolean = false;
  public token:string = '';

  constructor(private router: Router,
              private authService: AuthService,
              public matDialog: MatDialog,
              public servicesService:ServicesService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.token = localStorage.getItem('token_transporte');
    this.refresh(this.registerUser.id, this.registerUser.perfil);
    this.notificaciones();
    this.nombrePerfil();
    this.perfilesdelusuario();
    this.perf = this.registerUser.perfil
  }

  logout(){
    localStorage.removeItem('token_transporte');
    localStorage.removeItem('data');
    this.router.navigate(['/login']);
  }

  nombrePerfil(){
    const perf = Number(this.registerUser.perfil);
    switch (perf){
      case 1: this.perfil = 'Administrador'; break;
      case 2: this.perfil = 'Cargador'; break;
      case 3: this.perfil = 'Descargador'; break;
      case 4: this.perfil = 'Transportista'; break;
    }
  }

  refresh(id:number, perfil:number){
    this.authService.getProfileUser({idUser:id, idPerfil:perfil}).subscribe((resp:any)=>{
      if(resp.response[0]){
        const {avatar, alias} = resp.response[0];
        this.registerUser.alias = alias;
        this.avatar = avatar;
      }
    });
  }

  link(notify:any){

    if(notify.flag == 1){
      this.viewModalCalificaciones({
        id:notify.user_evalua,
        perfil:notify.perfil_evalua,
        id_anuncio:notify.id_anuncio})
    }
  }

  async viewModalCalificaciones(dataUser:any){

    const goQualify = await this.checkIsScore(dataUser);

    if(!goQualify){
      Swal.fire('El usuario ya ha sido calificado','','warning');
      return;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = dataUser;

    const dialogRef = this.matDialog.open(DialogCalificaComponent,{
      ... dialogConfig,
      width:'550px',
      height:'460px'
    });

    dialogRef.afterClosed().subscribe(resp => {})

    /** Dado que el modal puede tener varias vistas ('view', 'sended'),
     * se envía el valor de parámetro 'view'
     * que permite tener la vista para configurar la puja. */


    /*
    dialogConfig.data = {... iOffer,
                        listTypeVehicles:this.listTypeVehicles,
                        mode:'view'}
    const dialogRef = this.dialog.open(DialogPujaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if(data){
        const idx = this.listOffers.findIndex(o => o.id == iOffer.id);
        this.listOffers[idx]['idpuja'] = (data.idpuja)?data.idpuja:1;
      }
    })
    */

  }

  checkIsScore(dataNotif){
    const data = {
      id_usuario:dataNotif.id,
      id_anuncio:dataNotif.id_anuncio
    }
    return new Promise((resolve) => {
      this.authService.checkIfScore(data).subscribe((resp:any)=>{
        if(resp.code==200){
          resolve(resp.response)
        }
      });
    })
  }

  viewModalPerfil(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.matDialog.open(DialogPerfilComponent,{

    });
  }

  notificaciones(){
    this.servicesService.notificaciones(this.registerUser.id).subscribe(resp =>{
      if(resp.code == 200){
        this.notificacion = resp.notificacion;
      }
    })

    this.servicesService.getNoLeidos(this.registerUser.id).subscribe(resp =>{
      if(resp.code == 200){
        this.newNotif = resp.count[0].conteo;
      }
    })

    //Mensajes sin leer
    this.servicesService.getMsgNoLeidos(this.registerUser.id).subscribe(resp =>{
      if(resp.code == 200){
        this.newMsg = resp.count;
      }
    })

    const options = {
      broadcaster: 'pusher',
      key: environment.keyPusher,
      cluster: 'us2',
    };

    const echo = new Echo({
      ...options,
      wsHost: window.location.hostname,
      forceTLS: false,
      wsPort: 6001,
      client: new Pusher(options.key, options),
      disableStats: true,
      enabledTransports: ['ws'],
    });

    echo.channel(`App.User.${this.registerUser.id}`)
    .listen('.prueba',(resp) => {
      this.pushNofication(resp['user'])
    })
  }

  pushNofication(item){
    this.notificacion.unshift({
      notificacion:item.mensaje,
      momento: 'Ahora',
      estado:0,
      flag:item.flag,
      user_evalua:item.user_evalua,
      perfil_evalua:item.perfil_evalua,
      avatar:item.avatar
    });
    this.newNotif++;
  }

  setReaded(){
    if (!this.viewNotif){
      const notifReaded = this.notificacion.filter(n=>n.estado==0);
      notifReaded.map(n=>{
        this.servicesService.setReaded(n.id).subscribe();
      });

      this.viewNotif = true
    }
  }



  switchPerfil(indice:number){
    localStorage.removeItem('token_transporte');
    localStorage.removeItem('data');

    const token = this.perfilesActivos[indice].token;
    localStorage.setItem("token_transporte", token);

    this.data.id = this.perfilesActivos[indice].id_usuario;
    this.data.name = this.perfilesActivos[indice].nombre_usuario;
    this.data.perfil = this.perfilesActivos[indice].idperfil;
    this.data.alias = this.perfilesActivos[indice].alias_usuario;
    localStorage.setItem('data',JSON.stringify(this.data));

    switch (this.perfilesActivos[indice].idperfil){
      case 2:
          this.router.navigate(['/pagina/principal/cargador']);
          break;
      case 3:
          this.router.navigate(['/pagina/principal/descargador']);
          break;
      case 4:
          this.router.navigate(['/pagina/principal/transportista']);
          break;
    }

    this.perfilesdelusuario();
  }


  perfilesdelusuario(){
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    const data = {
      "idusuario":this.registerUser.id,
      "idperfil":this.registerUser.perfil,
      "token":this.token
    }

    this.servicesService.perfilesdelusuario(data).subscribe(resp =>{
      if(resp.code == 200){
        localStorage.removeItem('perfDis');
        this.perfilesActivos = resp.perfiles_activos_user;
        this.perfilesPorConfirmar = resp.perfiles_espera_confirmacion;
        this.perfilesPuedeSolicitar = resp.perfiles_puede_solicitar;

        let i = 0;
        this.perfilesPuedeSolicitar.forEach(id =>{
          this.dataPerf[i] = id.id;
          i++;
        })

        localStorage.setItem("perfDis",JSON.stringify(this.dataPerf));
      }
    })
  }
}
