import { Component } from '@angular/core';

@Component({
  selector: 'app-transportista-sugerido',
  templateUrl: './transportista-sugerido.component.html',
  styleUrls: ['./transportista-sugerido.component.scss']
})
export class TransportistaSugeridoComponent {

}
