<div class="row">
    <div class="col-12">
        <div class="card ">
            <div class="card-body border-bottom">


                <div>
                  <form [formGroup]="searchForm" class="d-xl-block">
                    <div class="filters">
                      <div class="buscar">
                        <div class="app-search position-relative">
                          <input type="search" class="form-control" placeholder="Código anuncio" formControlName="codigo_anuncio">
                          <span class="bx bx-search-alt"></span>
                        </div>
                      </div>
                      <input type="search" class="origen form-control" placeholder="Origen" formControlName="origen">
                      <input type="search" class="destino form-control" placeholder="Destino" formControlName="destino">
                      <input type="text" onkeydown="return false"
                              class="desde form-control" placeholder="Desde"
                              onfocus="(this.type='date')"
                              onblur="(this.type= this.value ? 'date' : 'text')"
                              formControlName="desde">
                      <div class="error errDesde" *ngIf="f.desde.errors?.required">Es obligatorio el rango completo de fechas</div>
                      <div class="error errDesde" *ngIf="f.desde.errors?.rango">La fecha de inicio no puede ser superior a la fecha final</div>
                      <input type="text" onkeydown="return false"
                              class="hasta form-control" placeholder="Hasta"
                              onfocus="(this.type='date')"
                              onblur="(this.type= this.value ? 'date' : 'text')"
                              formControlName="hasta">
                      <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid" (click)="search()">
                        <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                        Filtrar
                      </button>
                    </div>
                  </form>
                </div>


            </div>

            <div class="card-body">
              <ul>
                <li *ngFor="let offer of listOffers">
                  <div class="col-12">
                    <div class="row g-3">
                        <div class="col-sm-6">
                          <div><i>Código: {{offer.codigo_anuncio}}</i></div>
                          <label style="display: flex; flex-direction: column; font-size: 25px;">
                            <b>Viaje {{offer.ciudad_origen}} - {{offer.ciudad_destino}}</b>
                          </label>
                        </div>
                        <div class="col-sm-6">
                          <div class="col-12">
                            <div class="row g-3">
                              <div class="col-sm-2">
                                <button type="button" *ngIf="offer.idpuja && offer.idpuja > 1"
                                    class="btn btn-secondary w-100"
                                    (click)="deletePuja(offer)">
                                    <i class="fa fa-trash"></i>
                                </button>
                              </div>
                              <div class="col-sm-5">
                                <button type="button"
                                        class="btn btn-secondary w-100"
                                        (click)="viewModalAnuncio(offer)">
                                    Ver propuesta completa
                                </button>
                              </div>
                              <div class="col-sm-3">
                                  <button type="button"
                                      class="btn btn-secondary w-100"
                                      [ngClass]="{'selected':offer.idpuja}"
                                      (click)="viewModal(offer)">
                                      {{offer.idpuja ? 'Pujado' : 'Pujar'}}
                                  </button>
                              </div>
                              <div class="col-sm-2">
                                  <button type="button"
                                      class="btn btn-secondary w-100"
                                      [ngClass]="{'selected':offer.idfavoritos}"
                                      (click)="saveOffer(offer)">
                                      <img src="assets/images/transfriendly/heart.png">
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="row g-3" class="col-sm-5 pb-2">
                        <button type="button" class="btn btn-primary">
                            <img src="assets/images/transfriendly/camion.png">
                            {{offer.transporte}}
                        </button>
                    </div>


                    <div class="row">
                      <div class="col-sm-3">
                        <button style="width: 100%;" type="button" class="btn btn-secondary" *ngIf="offer.puja_minima != offer.puja_maxima">
                          Puja inferior {{offer.puja_minima  | formatNumberPipe }} €/Tn
                        </button>
                      </div>

                      <div class="col-sm-3">
                        <button style="width: 100%;" type="button" class="btn btn-secondary" *ngIf="offer.puja_maxima != null">
                          Puja superior {{offer.puja_maxima  | formatNumberPipe }} €/Tn
                        </button>
                      </div>
                    </div>


                    <div class="row" class="col-sm-12 py-3">
                        <div style="display: flex; flex-direction: row; align-items: center; gap: 12px;">
                            <span *ngIf="!offer.validPuja">{{ offer.monto | formatNumberPipe }} €/Tn</span>
                            <span *ngIf="!offer.validPuja"><img src="assets/images/transfriendly/point.png"></span>
                            <span>Fecha de salida: {{offer.fecha_viaje | date:'d/MM/yyyy'}}</span>
                            <span><img src="assets/images/transfriendly/point.png"></span>
                            <span>Pago por {{offer.forma_pago}}</span>
                            <span><img src="assets/images/transfriendly/point.png"></span>
                            <span>{{offer.tipo_viaje}}</span>
                        </div>
                    </div>
                    <div class="row" class="col-sm-12">
                      {{offer.descripcion}}
                    </div>
                    <div class="row g-3 pt-4 pb-1 border-bottom">
                        <div class="col-sm-6" style="display: flex; gap: 0 3em;">
                          <label
                              *ngIf = "offer.pago_verificado == 1"
                              style="display: flex;align-items: center; gap: 8px; font-size: 14px; ">
                              <i class="sidenav-link-icon fa fa-check-circle ok"></i>
                              <b style="color:black">Pago certificado</b>
                          </label>
                          <label
                              *ngIf = "offer.partner_verificado == 1"
                              style="display: flex;align-items: center; gap: 8px; font-size: 14px; ">
                              <i class="sidenav-link-icon fa fa-check-circle ok"></i>
                              <b style="color:black">Partner</b>
                          </label>
                        </div>
                        <div class="col-sm-6" style="text-align: right; color:#9b9b9b">
                            <span>
                                Publicado hace {{getTime(offer)}} por {{offer.alias_cargador}}
                            </span>
                        </div>
                    </div>
                </div>
                </li>
              </ul>
            </div>
            <mat-paginator [length]="dataSource.length"
                  [pageSize]="size"
                  [pageIndex]="page"
                  [pageSizeOptions]="[5,10,25,50]"
                  (page)="linkListToPaginator($event)">
            </mat-paginator>
        </div>
    </div>
</div>





