<ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
  <p style="font-size: 20px; color: white">Por favor espere ...</p>
</ngx-spinner>

<div class="bodyDash" style="padding: 3rem 4rem 3rem 6rem;">
  <h2>Tiempo real</h2>
  <div class="card">
    <div class="card-body">
      <div class="title-section">Pujas</div>
      <!-- <div class="count-section">Total de pujas ( )</div> -->
      <table mat-table [dataSource]="dataSourcePujas" matSort #empTbSort="matSort" >
        <ng-container matColumnDef="codigo_puja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let element"> {{element.codigo_puja}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre_usuario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre_usuario}} </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Puja</th>
          <td mat-cell *matCellDef="let element"> {{element.created_at | date:'dd/MM/yyyy' }} </td>
        </ng-container>
        <ng-container matColumnDef="estatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
            <span [ngClass]="{'Aceptada':'state ok','En espera':'state pending','Rechazada':'state reject'}[element.estatus]">
              {{element.estatus}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="valor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
          <td mat-cell *matCellDef="let element"> {{ element.valor | formatNumberPipe }}€/Tn</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
            <a class="btn btn-sm btn-soft-primary w-100"
            (click)="viewPuja(modalPuja,element)">
              <i class="mdi mdi-eye-outline"></i>
              Ver detalles
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colsPujas"></tr>
        <tr mat-row *matRowDef="let row; columns: colsPujas;"></tr>
      </table>
      <div style="display: flex; justify-content: space-around;">
        <!-- <button type="button" class="filtrar btn btn-primary" style="width: 40%;">
          Historial de pujas
        </button> -->
        <mat-paginator #paginatorPujas="matPaginator" style="flex: 1;" [pageSize]="6"></mat-paginator>

      </div>

    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="title-section">Viajes</div>
      <!-- <div class="count-section">Total de viajes ( )</div> -->

    <div>
        <form class="d-xl-block" [formGroup]="searchTravelsForm">
          <div class="filters">
            <input type="text" class="origen form-control" placeholder="Origen" formControlName="origen">
            <input type="text" class="destino form-control" placeholder="Destino" formControlName="destino">
            <input type="text"
                  class="desde form-control"
                  placeholder="Desde"
                  formControlName="fecha_inicio"
                  onkeydown="return false"
                  onfocus="(this.type='date')"
                  onblur="(this.type= this.value ? 'date' : 'text')"
                  >
            <input type="text" onkeydown="return false"
                    class="hasta form-control" placeholder="Hasta"
                    onfocus="(this.type='date')"
                    onblur="(this.type= this.value ? 'date' : 'text')"
                    formControlName="fecha_fin">
            <select class="tvehiculo form-select" formControlName="estado">
              <option value="" selected>Estado</option>
              <option *ngFor="let estado of listEstados" [value]="estado.idestado" >{{estado.descripcion}}</option>
            </select>

            <button type="button" class="exportar btn btn-secondary" (click)="exportExcelViajes()">
              Exportar
            </button>

            <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchTravelsForm.valid" (click)="searchTravels()">
              <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
              Filtrar
            </button>
          </div>
          <div class="error errDesde" *ngIf="ftrv.fecha_inicio.errors?.required">Es obligatorio el rango completo de fechas</div>
          <div class="error errDesde" *ngIf="ftrv.fecha_inicio.errors?.rango">La fecha de inicio no puede ser superior a la fecha final</div>

        </form>
    </div>

    <table mat-table [dataSource]="dataSourceTravels" matSort #empTbSorTrv="matSort">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let element"> {{element.codigo_anuncio}} </td>
      </ng-container>
      <ng-container matColumnDef="places">
        <th mat-header-cell *matHeaderCellDef > Origen - Destino </th>
        <td mat-cell *matCellDef="let element"> {{element.ciudad_origen}} - {{element.ciudad_destino}}</td>
      </ng-container>
      <ng-container matColumnDef="fechasalida">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de salida </th>
        <td mat-cell *matCellDef="let element"> {{element.fechasalida | date:'dd/MM/yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef > Valor </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          {{ element.valor | formatNumberPipe }} €/Tn</td>
      </ng-container>
      <ng-container matColumnDef="estatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          <span [ngClass]="{'POR INICIAR':'state start','EN CURSO':'state pending','FINALIZADO':'state end'}[element.estatus]">
            {{element.estatus}} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
          <a class="btn btn-sm btn-soft-primary" (click)="viewDetailsTravels(element)">
            <i class="mdi mdi-eye-outline"></i>
            Ver detalles
          </a>
          <a class="btn btn-sm btn-soft-primary" (click)="dowloadDocsTravels(element)">
            <i class="mdi mdi-eye-outline"></i>
            Descargar docs
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colsTravels"></tr>
      <tr mat-row *matRowDef="let row; columns: colsTravels;"></tr>
    </table>

    <mat-paginator #paginatorTrv="matPaginator" style="flex: 1;" [pageSize]="6"></mat-paginator>

    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="title-section">Transportistas contratados</div>

      <div>
        <form class="d-xl-block" [formGroup]="searchTransportistasForm">
          <div class="filters">
            <input type="text" class="origen form-control" placeholder="Nombre" formControlName="nombre">
            <input type="text" class="origen form-control" placeholder="Origen" formControlName="origen">
            <input type="text" class="destino form-control" placeholder="Destino" formControlName="destino">
            <input type="text"
                  class="desde form-control"
                  placeholder="Fecha de inicio"
                  formControlName="fecha_inicio"
                  onkeydown="return false"
                  onfocus="(this.type='date')"
                  onblur="(this.type= this.value ? 'date' : 'text')">

            <button type="button" class="exportar btn btn-secondary" (click)="exportExcelTransport()">
              Exportar
            </button>

            <button type="button" class="filtrar btn btn-primary w-100" (click)="searchTransport()">
              <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
              Filtrar
            </button>
          </div>
        </form>
      </div>

      <table mat-table [dataSource]="dataSourceTransportistas" matSort>
        <ng-container matColumnDef="codigo_anuncio">
          <th mat-header-cell *matHeaderCellDef> Código </th>
          <td mat-cell *matCellDef="let element"> {{element.codigo_anuncio}} </td>
        </ng-container>
        <ng-container matColumnDef="fechasalida">
          <th mat-header-cell *matHeaderCellDef> Fecha de salida </th>
          <td mat-cell *matCellDef="let element"> {{element.fechasalida | date:'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="ciudad_origen">
          <th mat-header-cell *matHeaderCellDef > Origen </th>
          <td mat-cell *matCellDef="let element"> {{element.ciudad_origen}} </td>
        </ng-container>
        <ng-container matColumnDef="ciudad_destino">
          <th mat-header-cell *matHeaderCellDef > Destino </th>
          <td mat-cell *matCellDef="let element"> {{element.ciudad_destino}}</td>
        </ng-container>
        <ng-container matColumnDef="monto">
          <th mat-header-cell *matHeaderCellDef > Monto </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.monto | formatNumberPipe}} €/Tn </td>
        </ng-container>
        <ng-container matColumnDef="cargador">
          <th mat-header-cell *matHeaderCellDef > Destinatario </th>
          <td mat-cell *matCellDef="let element"> {{element.cargador}}</td>
        </ng-container>
        <ng-container matColumnDef="nombre_transportista">
          <th mat-header-cell *matHeaderCellDef > Transportista </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre_transportista}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
            <a class="btn btn-sm btn-soft-info"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
                title="Datos Conductor"
                (click)="viewContact(element)">
              <i class="mdi mdi-account"></i>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colsTransportistas"></tr>
        <tr mat-row *matRowDef="let row; columns: colsTransportistas;"></tr>
      </table>

      <mat-paginator #paginatorTransp="matPaginator" style="flex: 1;" [pageSize]="6"></mat-paginator>
    </div>
  </div>

  <h2>Otros datos</h2>
  <div class="cifras">
    <div class="card">
      <div class="title-section">Anuncios Activos</div>
      <div class="value">{{statistics.cifras?.anuncios_activos}}</div>
    </div>
    <div class="card">
      <div class="title-section">Anuncios Finalizados</div>
      <div class="value">{{statistics.cifras?.anuncios_finalizados}}</div>
    </div>
    <div class="card">
      <div class="title-section">Anuncios eliminados</div>
      <div class="value">{{statistics.cifras?.anuncios_eliminados}}</div>
    </div>
    <div class="card">
      <div class="title-section">Usuarios Activos</div>
      <div class="value">{{statistics.cifras?.usuarios_activos}}</div>
    </div>
    <div class="card">
      <div class="title-section">Usuarios inhabilitados</div>
      <div class="value">{{statistics.cifras?.usuarios_inhabilitados}}</div>
    </div>
    <div class="card">
      <div class="title-section">Usuarios roles</div>
      <div class="value">{{statistics.cifras?.usuarios_roles}}</div>
    </div>

  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Ganancias</h4>
          <div class="chartjs-chart">
            <!-- Line Chart -->
            <canvas baseChart height="300" [datasets]="chartProfit.datasets" [labels]="chartProfit.labels"
              [options]="chartProfit.options" chartType="line">
            </canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Registro de nuevos usuarios</h4>
          <div class="row text-center">
            <div class="col-4" *ngFor="let label of this.chartUsers.labels;let i = index" >
              <h5 class="mb-0">{{chartUsers.datasets[0].data[i]}}</h5>
              <p class="text-muted text-truncate">{{label}}</p>
            </div>
          </div>
          <div class="">
            <!-- Bar Chart -->
            <canvas baseChart [datasets]="chartUsers.datasets" chartType="doughnut" [options]="chartUsers.options"
              height="260" [labels]="chartUsers.labels">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<ng-template #modalPuja let-modal style="width: 340px;">

  <div class="modal-body" style="max-height: 350px;overflow-y: auto;">
    <button type="button" style="position: absolute;right: 15px;" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
    <div style="text-align: center;font-size: 1.3em;margin-bottom: 1em;">
      <div style="margin-bottom: 0.5rem;">El monto pujado es</div>
      <div class="amount">{{ dataPuja.valor | formatNumberPipe}}€/Tn</div>
    </div>
    <dl>
      <dt>Código anuncio</dt>
      <dd>{{ dataPuja.codigo_anuncio }}</dd>
      <dt>Transportista</dt>
      <dd>{{dataPuja.nombre_usuario}}</dd>
      <dt>Vehículo</dt>
      <dd>{{dataPuja.tipo_vehiculo}}</dd>
      <dt>Fecha (opcional)</dt>
      <dd *ngIf="!dataPuja.fecha_viaje.includes('0000')">
        {{dataPuja.fecha_viaje | date:'dd/MM/yyyy'}} </dd>
    </dl>
    <label>Y su descripción es:</label>
    <div class="comentarios">{{dataPuja.comentario}}</div>
  </div>
</ng-template>
