import { Component, OnInit } from '@angular/core';
import { AdministradorService } from '../../pages/dashboards/services/administrador.service';
import { Lista } from '../../core/models/lista.models';
import { NgxSpinnerService } from "ngx-spinner";
import { Perfil } from 'src/app/core/models/perfiles.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCalificaComponent } from '../dialog-califica/dialog-califica.component';

export interface workDone {
  fechasalida: string;
  ciudad_origen: string;
  ciudad_destino: string;
  idPaymentType: string;
  descripcion:string;
  valor:number;
};
@Component({
  selector: 'app-lista-transportista',
  templateUrl: './lista-transportista.component.html',
  styleUrls: ['./lista-transportista.component.scss']
})

export class ListaTransportistaComponent implements OnInit {
  public page = 1;
	public pageSize = 10;
	public collectionSize = 0;
  public pagina:Lista[];
  public transportista :Lista[];
  public usuario:Perfil;
  private idUser;
  private idPerfil = 4;
  public tag:number = 1;
  public perfiles_activos:any;

  listWorkDone:workDone[] = [];
  dataSourceOfferHistory:workDone[] = [];
  pageModal:number=0;
  sizeModal:number=5;

  searchTransportista: FormGroup;

  initUserScore = {
    id:null,
    alias: null,
    email: null,
    avatar: null,
    calificaciones:[]
  };
  userScore:any = {};

  private modalRef: any;

  constructor(private administradorService:AdministradorService,
              private ngxSpinnerService:NgxSpinnerService,
              private ngbModal:NgbModal,
              private servicesService: ServicesService,
              private fb: FormBuilder,
              private snackBar:MatSnackBar,
              public matDialog: MatDialog){}

  ngOnInit(): void {
    this.loadSearchUsers();
    this.getTransportista();
    this.perfiles_activos = [];
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 1000);
  }

  refreshPagina() {
    this.pagina = this.transportista.map((data, i) => ({ id: i + 1, ...data })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  loadSearchUsers(){
    this.searchTransportista = this.fb.group({
      nombre:['']
    });
  }

  searchUsuarios(){
    this.pagina = [];
    this.getTransportista()
  }

  getTransportista(){
    this.ngxSpinnerService.show()

    let pSearch = this.formatSearch(this.searchTransportista.value);

    this.administradorService.consultarUsuarioPerfil(pSearch,this.idPerfil).subscribe(resp=>{
      if(resp.status == "ok" && resp.code == "200"){
        this.transportista = resp.usuarios;
        this.collectionSize = this.transportista.length;
        this.refreshPagina();
      }else{
        this.snackBar.open(resp['usuarios'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    });

    this.stopSpinner();
  }



  largeModal(largeDataModal: any, id?:number){
    this.idUser = id;
    this.modalRef = largeDataModal;
    this.ngbModal.open(largeDataModal, { size: 'lg', centered: true });
    this.consultarUsuario(id);
  }

  consultarUsuario(id:number){
    this.tag = 1;
    this.usuario = {} as Perfil;
    this.administradorService.consultarUsuario(id).subscribe(resp =>{
      if(resp.status == "ok"){
        if(resp.usuario != null){
          this.usuario = resp.usuario[0];
          this.perfiles_activos = resp.perfiles_activos;
        }
      }
    });

    this.loadScore(id)
    this.loadOffersHistory(id)
  }

  getFiles(typefile:number):any{
    let listFile=[];
    Object.entries(this.usuario.archivo).forEach(([key, value]) => {
      if (value['tipo_archivo'] == typefile) listFile.push(value)
    });
    return listFile;
  }

  loadScore(idUser){
    this.userScore = this.initUserScore;
    this.servicesService.consultaCalificaciones(idUser).subscribe((resp:any) =>{
      if(resp.code == 200 && resp.usuario){
        this.userScore = {
          id:resp.usuario[0].id,
          alias: resp.usuario[0].alias,
          email: resp.usuario[0].email,
          avatar: resp.usuario[0].avatar,
          calificaciones:resp.calificaciones,
          total_calificaciones:resp.total_calificaciones,
          promedio:resp.promedio
        }
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))
  }

  loadOffersHistory(idUser){
    this.servicesService.getListFinishedOffers({idUser}).subscribe((resp: any) => {
      if (resp.code == '200') {
        this.dataSourceOfferHistory = <workDone[]>resp.response;
        this.loadPage({pageIndex: this.pageModal, pageSize: this.sizeModal});
      }
    })
  }

  loadPage(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listWorkDone = this.dataSourceOfferHistory.slice(from, to);
  }

  changeTag(t:number){
    this.tag = t;
  }

  downloadFile(email:string, archivo:number, nombre:string){
    this.administradorService.downloadFile(email,archivo).subscribe(resp=>{
      saveAs(resp,nombre),
      error => console.error(error)
    })
  }

  //Depura la información del formulario para la búsqueda o filtro
  formatSearch(dataSearchForm:any){
    let pSearch={};
    Object.entries(dataSearchForm).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })
    return pSearch;
  }


  eliminarUsuario(id:number){
    Swal.fire({
      title: 'Estas seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicesService.eliminarUsuario(id).subscribe(resp =>{
          if(resp.code == 200){
            Swal.fire('Eliminado!','Usuario ha sido eliminado correctamente','success');
            this.getTransportista();
          }
        },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }));
      }
    })
  }

  modalScore(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id:this.idUser,
      perfil: this.idPerfil}

    const dialogRef = this.matDialog.open(DialogCalificaComponent,{
      ... dialogConfig,
      width:'550px',
      height:'460px'
    });

    dialogRef.afterClosed().subscribe(resp => {
      this.largeModal(this.modalRef, this.idUser)
    })
  }

  sendDocsRequest(){
    this.servicesService.sendDocsRequest(this.usuario.id).subscribe(resp =>{
      if(resp.code == 200){
        this.snackBar.open(resp['msj'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }));
  }
}
