import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormArray, FormControl, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from '../../pages/components/viaje-multiple/services.service';

@Component({
  selector: 'app-trayecto-dos',
  templateUrl: './trayecto-dos.component.html',
  styleUrls: ['./trayecto-dos.component.scss']
})


export class TrayectoDosComponent {

  @Input() formGroupName: string;
  public trayectoAdicional: FormGroup;
  public orden:File;
  public descargadores:any;
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public verQuien:boolean;
  public zp:number=0;

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective,
              private servicesService:ServicesService){}

  ngOnInit(): void {
    this.trayectoAdicional = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.registerUser = JSON.parse(localStorage.getItem('data'));

    let arreglo = this.trayectoAdicional.get('campos') as FormArray;

    if(arreglo.length == 0){
      this.addTrayecto();
    }

    this.getDescargadores();
  }

  onFileChange(event, indice:number){
    if(event.target.files.length > 0){
      let documento = this.trayectoAdicional.get('campos') as FormArray;
      let doc = documento.controls[indice] as FormGroup;
      doc.get('orden_entrega').setValue(event.target.files[0]);
      doc.get('orden_entrega_name').setValue(event.target.files[0].name);
    }
  }

  addTrayecto():void {
    (<FormArray>this.trayectoAdicional.get('campos')).push(new FormGroup({
        origen_provincia: new FormControl('',[Validators.required]),
        origen_ciudad: new FormControl('',[Validators.required]),
        origen_calle: new FormControl('',[Validators.required]),
        origen_numero: new FormControl('',[Validators.required]),
        origen_cod_postal: new FormControl('',[Validators.required]),

        destino_provincia: new FormControl('',[Validators.required]),
        destino_ciudad: new FormControl('',[Validators.required]),
        destino_calle: new FormControl('',[Validators.required]),
        destino_numero: new FormControl('',[Validators.required]),
        destino_cod_postal: new FormControl('',[Validators.required]),

        fecha_maxima: new FormControl(''),
        cantidad_articulos: new FormControl(0,[Validators.required, Validators.min(1)]),
        orden_entrega: new FormControl(null),
        orden_entrega_name: new FormControl(''),

        descripcion: new FormControl(''),

        quien_recibe: new FormControl(-1,[Validators.required, Validators.min(0)]),

        verquien:new FormControl(false),

        nombre: new FormControl(''),
        dni: new FormControl(''),
        telefono: new FormControl(''),
        correo: new FormControl(''),


        muelle: new FormControl(false),
        transpaleta: new FormControl(false),
        carretilla: new FormControl(false),
    }));
  }

  cerrar(indice:number){
    (<FormArray>this.trayectoAdicional.get('campos')).removeAt(indice)
  }


  getDescargadores(){
    this.servicesService.descargadores(this.registerUser.id, this.zp).subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.descargadores = resp.response;
      }
    });
  }


  invalidate(nameFormControl: string, indice:number){
    let trayecto = this.trayectoAdicional.get('campos') as FormArray;
    let tray = trayecto.controls[indice] as FormGroup;

    let valida = { value: false };
    if((tray.get(nameFormControl)?.touched ||
        tray.get(nameFormControl)?.dirty) &&
        tray.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateQuienRecibe(nameFormControl: string, indice:number){
    let trayecto = this.trayectoAdicional.get('campos') as FormArray;
    let tray = trayecto.controls[indice] as FormGroup;

    let valida = { value: false };
    if(tray.get(nameFormControl)?.touched && tray.get(nameFormControl)?.value == -1)
    { valida.value = true; }

    return valida
  }


  getQuienrecibe(indice:number){
    let documento = this.trayectoAdicional.get('campos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    const quien =  doc.get('quien_recibe').value
    if(quien == 9999)
    { this.verQuien = true
      doc.get('verquien').setValue(true)
      doc.get('nombre').addValidators([Validators.required]);
      doc.get('telefono').addValidators([Validators.required]);
    }
    else
    { this.verQuien = false
      doc.get('verquien').setValue(false)

      doc.get('nombre').clearValidators();
      doc.get('telefono').clearValidators();

      doc.get('nombre').setValue('')
      doc.get('dni').setValue('')
      doc.get('telefono').setValue('')
      doc.get('correo').setValue('')
    }
  }


  verquien(indice:number):boolean{
    const documento = this.trayectoAdicional.get('campos') as FormArray;
    const doc = documento.controls[indice] as FormGroup;
    const verquien =  doc.get('verquien').value;
    return verquien
  }


  zonaPostal(event: any) {
    this.zp = event.target.value
    this.getDescargadores()
  }
}
