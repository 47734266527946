import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ViajesPagoComponent } from './viajes-pago/viajes-pago.component';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge } from 'rxjs/internal/observable/merge';
import { viajesRealizados } from '../../core/models/viajesRealizados.models';
import { utils, writeFile } from 'xlsx';
import { TitleStrategy } from '@angular/router';
export interface workDone {
  fechasalida: string;
  ciudad_origen: string;
  ciudad_destino: string;
  idPaymentType: string;
  descripcion:string;
  valor:number;
};

@Component({
  selector: 'app-viajes-realizados',
  templateUrl: './viajes-realizados.component.html',
  styleUrls: ['./viajes-realizados.component.scss']
})

export class ViajesRealizadosComponent implements OnInit{

  public fileName = 'ViajesRealizados.xlsx';
  public viajesRealizados: viajesRealizados[] = [];

  searchForm: FormGroup;
  listWorkDone:workDone[] = [];
  dataSource:workDone[] = [];
  page:number=0;
  size:number=5;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
    private servicesService: ServicesService,
    private fb: FormBuilder){

  }

  ngOnInit(): void {
    this.loadSearch();
    this.loadData();
  }

  loadData(){

    // Toma los valores de parámetros de búsqueda
    let pSearch={};
    Object.entries(this.searchForm.value).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })

    this.servicesService.getListFinishedOffers(pSearch).subscribe((resp: any) => {
      if (resp.code == '200') {
        this.dataSource = <workDone[]>resp.response;
        this.viajesRealizados = resp.response.map(respuesta=>{ return{
                                codigo_anuncio:respuesta.codigo_anuncio,
                                ciudad_origen:respuesta.ciudad_origen,
                                ciudad_destino:respuesta.ciudad_destino,
                                descripcion:respuesta.descripcion,
                                fechasalida:respuesta.fechasalida,
                                valor:respuesta.valor,
        }});
        this.linkListToPaginator({pageIndex: this.page, pageSize: this.size});
      }
    })
  }

  // Método para acceso fácil a los campos del formulario
  get f() { return this.searchForm.controls; }

  loadSearch(){
    this.searchForm = this.fb.group({
      codigo_anuncio:[''],
      origen:[''],
      destino:[''],
      desde:[''],
      hasta:['']
    });

    merge(this.searchForm.get('desde').valueChanges,
          this.searchForm.get('hasta').valueChanges)
    .subscribe(() =>{
      this.setValidatorsDate(this.searchForm.get('desde').value,this.searchForm.get('hasta').value);
    })
  }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchForm.get('desde').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchForm.get('desde').setErrors({'rango': true});
    }else{
      this.searchForm.get('desde').setErrors(null)
    }
  }

  search(){
    this.listWorkDone = [];
    this.dataSource = [];
    this.loadData();
  }

  linkListToPaginator(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listWorkDone = this.dataSource.slice(from, to);

  }

  viewPayment(offer:any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={idanuncio:offer.id}
    const dialogRef = this.dialog.open(ViajesPagoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
    })
  }


  exportexcel() {
    const headings = [['Codigo','Ciudad Origen','Ciudad Destino','Descripcion','Fecha Salida','Valor']];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.viajesRealizados, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, this.fileName);
  }
}
