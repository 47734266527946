<label style="display: flex; flex-direction: row; align-items: center; gap: 20px; font-size: 25px;">
  <b style="color:black">Mis expediciones</b>
</label>
<div class="row mt-2">
  <div class="col-12">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Solicitudes</a>
            <ng-template ngbNavContent>
              <app-descargas-solicitudes></app-descargas-solicitudes>
            </ng-template>
          </li>

          <li [ngbNavItem]="2">
            <a ngbNavLink>Próximas expediciones</a>
            <ng-template ngbNavContent>
              <app-descargas-proximas></app-descargas-proximas>
            </ng-template>
          </li>

          <li [ngbNavItem]="3">
            <a ngbNavLink>Expediciones realizadas</a>
            <ng-template ngbNavContent>
              <app-descargas-realizadas></app-descargas-realizadas>
            </ng-template>
          </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
