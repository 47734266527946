<ng-container [formGroup]="informationForm">
  <div class="row mt-2">
    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.NAME' | translate }} <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.NAME' | translate }}"
              class="form-control"
              formControlName="nombre"
              style="font-size: 12px; margin-top: -5px;"
              [ngClass]="{'is-invalid': invalidate('nombre').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.CIF' | translate }} <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="B12345678 / 12345678A / Y1234567Z"
              class="form-control"
              formControlName="cif"
              style="font-size: 12px; margin-top: -5px;"
              [ngClass]="{'is-invalid': invalidate('cif').value }">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.ADDRESS' | translate }}</label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.ADDRESS' | translate }}"
              class="form-control"
              formControlName="direccion"
              style="font-size: 12px; margin-top: -5px;">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.DNI' | translate }} <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.DNI' | translate }}"
              class="form-control"
              formControlName="telefono"
              style="font-size: 12px; margin-top: -5px;"
              [ngClass]="{'is-invalid': invalidate('telefono').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.PHONE' | translate }}</label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.PHONE' | translate }}"
              class="form-control"
              formControlName="telefonoOpcional"
              style="font-size: 12px; margin-top: -5px;">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.EMAIL1' | translate }} <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.EMAIL1' | translate }}"
              class="form-control"
              formControlName="correo"
              style="font-size: 12px; margin-top: -5px;"
              [ngClass]="{'is-invalid': invalidate('correo').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">{{ 'REGISTERCARGADOR.EMAIL2' | translate }} <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="{{ 'REGISTERCARGADOR.EMAIL2' | translate }}"
              class="form-control"
              formControlName="correoConfirmar"
              style="font-size: 12px; margin-top: -5px;"
              [ngClass]="{'is-invalid': invalidateCorreo2('correoConfirmar').value }">
    </div>
  </div>
</ng-container>

