import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class AdministradorService {

  constructor(public httpClient:HttpClient){}

  validarUsuario(email:string, valido:number, motivo?:string, observaciones?:string){
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("valido", valido);
    formData.append("motivo", motivo);
    formData.append("observacion", observaciones);
    return this.httpClient.post(`${API_URL}/validarUsuario`,formData)
  }

  validarNuevoRol(idusuario:number, valido:number, motivo?:string, observacion?:string){
    const formData = {idusuario, valido, motivo, observacion}
    return this.httpClient.post(`${API_URL}/validarRol`,formData)
  }

  getListPerfiles():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/perfiles`)
  }

  consultarAllUsuarios(data):Observable<any>{
    return this.httpClient.post(`${API_URL}/consultarAllUsuarios`,data)
  }

  consultarUsuario(id:number):Observable<any>{
    let formData: any = new FormData();
    formData.append("id", id);
    return this.httpClient.post(`${API_URL}/consultarUsuario`,formData)
  }

  consultarUsuarioPerfil(data:any, perfil:number):Observable<any>{
    data.perfil = perfil;
    return this.httpClient.post(`${API_URL}/consultarUsuarioPerfil`,data)
  }

  downloadFile(email:string, archivo:number):Observable<Blob>{
    const data = { "email": email, "archivo": archivo }
    return this.httpClient.post(`${API_URL}/auth/downloadFile`,data,{responseType: 'blob'})
  }

  consultaPropuestas(data:any):Observable<any>{
    return this.httpClient.post(`${API_URL}/general/getencabezadoanuncio`,data)
  }

  consultaTiemposPagos():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/tiempopago`)
  }

  saveManagePay(data:any):Observable<any>{
    return this.httpClient.post(`${API_URL}/general/tiempopagocargador`,
    data)
  }

  valoracionPropuesta(data:any):Observable<any>{
    return this.httpClient.post(`${API_URL}/anuncio/validarPago`,
    data)
  }

  consultaMotivo():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/consultaMotivo`)
  }
}
