import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';

@Component({
  selector: 'app-viajes-pago',
  templateUrl: './viajes-pago.component.html',
  styleUrls: ['./viajes-pago.component.scss']
})
export class ViajesPagoComponent {

  dataDialog:any;
  dataOffer:any;
  footer:boolean = true;
  isAdmin = false;
  loading=true;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<ViajesPagoComponent>,
  private servicesService: ServicesService,
  private snackBar:MatSnackBar,){
    this.dataDialog=data;

    if(this.dataDialog && this.dataDialog.typeUser=='Admin'){
      this.footer = false;
      this.isAdmin = true;
    }

    this.loadData();
  }

  async loadData(){
    try {
      this.dataOffer = await this.getData();
      this.loading=false;
    } catch (error) {
      this.snackBar.open(error['info_general'],'Ok',{
        duration:3000,
        horizontalPosition:right,
        verticalPosition:bottom,
      });
      this.close();
    }
  }

  getData(){
    return new Promise((resolve, reject) => {
      this.servicesService.getAnuncioFinalizado({idanuncio:this.dataDialog.idanuncio}).subscribe((resp: any) => {
        if(resp.cod == 200){
          resolve(resp);
        }
        reject(resp);
      },error=>{
        reject(error);
      })
    })
  }

  get(field:string){
    if (this.dataOffer === undefined)return;
    switch (field) {
      case 'monto':
        return this.dataOffer.info_general[0].monto * (this.isAdmin?1.1:1)
        break;
      case 'puja':
          return this.dataOffer.info_general[0].monto
          break;
      case 'codigo_anuncio':
      case 'fechasalida':
      case 'forma_pago':
      case 'incidencia':
      case 'tipo_viaje':
        return this.dataOffer.info_general[0][field];
        break;
      default:
        break;
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(true);
  }
}
