import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cargador',
  templateUrl: './cargador.component.html',
  styleUrls: ['./cargador.component.scss']
})

export class CargadorComponent {

  constructor(private router: Router){}

  propuesta(){
    this.router.navigate(['/pagina/principal/propuesta']);
  }
}
