import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-adjunta-documentacion',
  templateUrl: './adjunta-documentacion.component.html',
  styleUrls: ['./adjunta-documentacion.component.scss']
})

export class AdjuntaDocumentacionComponent implements OnInit {

  @Input() formGroupName: string;
  public documentacion: FormGroup;
  public certificadoSeleccionado:boolean = false;
  public seguroSeleccionado:boolean = false;

  constructor(public languageService: LanguageService, private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.documentacion = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
  }

  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.documentacion.get(nameFormControl)?.touched ||
        this.documentacion.get(nameFormControl)?.dirty) &&
        this.documentacion.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  onFileChange(event){
    this.documentacion.controls['certificadoName'].setValue(event.target.files[0].name);
    this.documentacion.controls['certificado'].setValue(event.target.files[0]);
    this.certificadoSeleccionado = true;
  }

  onFileChangeSeguro(event){
    this.documentacion.controls['seguroName'].setValue(event.target.files[0].name);
    this.documentacion.controls['seguro'].setValue(event.target.files[0]);
    this.seguroSeleccionado = true;
  }
}
