import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViajesPagoComponent } from 'src/app/shared/viajes-realizados/viajes-pago/viajes-pago.component';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { DialogAnuncioComponent } from 'src/app/shared/dialog-anuncio/dialog-anuncio.component';
import { ViajeContactoComponent } from 'src/app/shared/viajes-proceso/viaje-contacto/viaje-contacto.component';
import { viajes } from '../../../core/models/viajes.models';
import { transportistaContrat } from '../../../core/models/transportistasContratados.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { bottom, right } from '@popperjs/core';
import { utils, writeFile } from 'xlsx';
import { merge } from 'rxjs/internal/observable/merge';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

interface listPujas {
  idpuja: number,
  codigo_puja: string,
  nombre_usuario: string,
  created_at: string,
  estatus: string,
  valor:number,
};

interface listTravels {
  id: number,
  codigo_anuncio:string,
  fechasalida: string;
  ciudad_origen: string;
  ciudad_destino: string;
  estatus: string;
};

interface listTransportista {
  id: number;
  idcontratacion:number;
  codigo_anuncio:string,
  fechasalida: string;
  ciudad_origen: string;
  ciudad_destino: string;
  monto: string;
  cargador: string;
};

@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.scss']
})
export class AdministradorComponent{

  public fileNameViajes = 'Viajes.xlsx';
  public viajes: viajes[] = [];

  public fileNameTransportistas = 'TransportistasContratados.xlsx';
  public transportistasContratados: transportistaContrat[] = [];

  colsPujas: string[] = ['codigo_puja',
                          'nombre_usuario',
                          'created_at',
                          'estatus',
                          'valor',
                          'actions'
                        ];

  dataSourcePujas: MatTableDataSource<listPujas>;

  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('paginatorPujas') paginator: MatPaginator;

  //---------------------------------

  searchTravelsForm: FormGroup;
  listEstados:{idestado:number,descripcion:string};
  colsTravels: string[] = ['id',
                            'places',
                            'fechasalida',
                            'valor',
                            'estatus',
                            'actions'
                          ];

  dataSourceTravels: MatTableDataSource<listTravels>;

  @ViewChild('empTbSorTrv') empTbSorTrv = new MatSort();
  @ViewChild('paginatorTrv') paginatorTrv: MatPaginator;
  //---------------------------------

  searchTransportistasForm: FormGroup;
  colsTransportistas: string[] = ['codigo_anuncio',
                                    'fechasalida',
                                    'ciudad_origen',
                                    'ciudad_destino',
                                    'monto',
                                    'cargador',
                                    'nombre_transportista',
                                    'actions'
                                  ];

  dataSourceTransportistas: MatTableDataSource<any>;

  // @ViewChild('empTbSorTrv') empTbSorTrv = new MatSort();
  @ViewChild('paginatorTransp') paginatorTransp: MatPaginator;

  dataPuja:any;

  statistics = {cifras:{
                  usuarios_activos:0,
                  usuarios_inhabilitados: 0,
                  usuarios_roles: 0,
                  anuncios_activos: 0,
                  anuncios_finalizados: 0,
                  anuncios_eliminados: 0,
                },
                charts:{
                  users:{},
                  valor_neto:{},
                  valor_incremento:{}
                }
              }

  chartProfit = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
    datasets: [
      {
        label: 'Ventas netas',
        fill: true,
        lineTension: 0.5,
        backgroundColor: 'rgba(85, 110, 230, 0.2)',
        borderColor: '#556ee6',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#556ee6',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#556ee6',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40, 55, 30, 80]
      }
    ],
    options: {
      defaultFontColor: '#8791af',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'rgba(166, 176, 207, 0.1)',
            },
          },
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              max: 5000,
              min: 0,
              stepSize: 500,
            },
            gridLines: {
              color: 'rgba(166, 176, 207, 0.1)',
            },
          },
        ],
      },

    }
  };

  chartUsers  = {
      labels: [],
      datasets: [
          {
              data: [],
              backgroundColor: [
                  '#556ee6', '#ebeff2', '#FFD098'
              ],
              hoverBackgroundColor: ['#556ee6', '#ebeff2'],
              hoverBorderColor: '#fff',
          }],
      options: {
          maintainAspectRatio: false,
          legend: {
              position: 'top',
          }
      }
  };

  constructor(public dialog: MatDialog,
              private servicesService: ServicesService,
              private ngxSpinnerService:NgxSpinnerService,
              private snackBar:MatSnackBar,
              private ngbModal:NgbModal,
              private fb: FormBuilder)
              {
                this.loadPujas();
                this.loadSearchTravels();
                this.loadEstados();
                this.loadTravels();
                this.loadStatistics();
                this.loadSearchTransport();
                this.loadTransportistas();
              }

  loadPujas(){
    this.servicesService.getListPujas().subscribe((resp: any) => {
      if (resp.status == 'ok') {
        this.dataSourcePujas = new MatTableDataSource(<listPujas[]>resp.pujas);
      }
      this.dataSourcePujas.sort = this.empTbSort;
      this.dataSourcePujas.paginator = this.paginator;
    })
  }

  searchTravels(){
    this.dataSourceTravels.data=[];
    this.loadTravels();
  }

  loadSearchTravels(){
    this.searchTravelsForm = this.fb.group({
      origen:[''],
      destino:[''],
      fecha_inicio:[''],
      fecha_fin:[''],
      estado:['']
    });

    merge(this.searchTravelsForm.get('fecha_inicio').valueChanges,this.searchTravelsForm.get('fecha_fin').valueChanges).subscribe(() =>{
      this.setValidatorsDate(this.searchTravelsForm.get('fecha_inicio').value,this.searchTravelsForm.get('fecha_fin').value);
    })
  }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchTravelsForm.get('fecha_inicio').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchTravelsForm.get('fecha_inicio').setErrors({'rango': true});
    }else{
      this.searchTravelsForm.get('fecha_inicio').setErrors(null)
    }
  }

  get ftrv() { return this.searchTravelsForm.controls; }

  loadEstados(){
    this.servicesService.getListEstados().subscribe((resp: any) => {
      if(resp.code == 200) this.listEstados = resp.response;
    })
  }

  loadTravels(){

    // Toma los valores de parámetros de búsqueda
    let pSearch = this.formatSearch(this.searchTravelsForm.value);

    this.servicesService.getListTotalTravels(pSearch).subscribe((resp: any) => {
      if (resp.code == '200') {
        this.dataSourceTravels = new MatTableDataSource(<listTravels[]>resp.response);

        this.viajes = resp.response.map(respuesta=>{ return{
                      codigo_anuncio:respuesta.codigo_anuncio,
                      ciudad_origen:respuesta.ciudad_origen,
                      ciudad_destino:respuesta.ciudad_destino,
                      fechasalida:respuesta.fechasalida,
                      valor:respuesta.valor,
                      estatus:respuesta.estatus,
        }});

      }else{
        this.snackBar.open(resp['response'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }

      this.dataSourceTravels.sort = this.empTbSorTrv;
      this.dataSourceTravels.paginator = this.paginatorTrv;
    })
  }

  loadStatistics(){
    this.servicesService.getDataStatistics().subscribe((resp: any) => {
      if (resp.code == 200) {
        this.statistics.cifras={
          usuarios_activos: resp.usuarios_activos,
          usuarios_inhabilitados: resp.usuarios_inhabilitados,
          usuarios_roles: resp.usuarios_roles,
          anuncios_activos: resp.anuncios_activos,
          anuncios_finalizados: resp.anuncios_finalizados,
          anuncios_eliminados: resp.anuncios_eliminados,
        }

        this.statistics.charts={
          users: resp.graficas.usuarios,
          valor_neto: resp.graficas.valor_neto,
          valor_incremento: resp.graficas.valor_incremento
        }

        // Gráfica Usuarios
        for (const [key, value] of Object.entries(this.statistics.charts.users)) {
          this.chartUsers.labels.push(key);
          this.chartUsers.datasets[0].data.push(value);
        }

        // Gráfica Ganancias
        this.chartProfit.labels = [];
        this.chartProfit.datasets[0].data = []; // Ventas netas
        let max:number = 0;
        for (const [key, value] of Object.entries(this.statistics.charts.valor_neto['ejeY'])) {
          this.chartProfit.labels.push(key);
          const inc = <number>this.statistics.charts.valor_neto['ejeY'][key];
          this.chartProfit.datasets[0].data.push(inc);
          max = (max > inc)?max:inc;
        }

        // this.chartProfit.options.scales.yAxes[0].ticks.max = max;

      }
      // console.log(this.statistics);
    })

  }

  // --------------------------------------------------------

  searchTransport(){
    this.dataSourceTransportistas.data=[];
    this.loadTransportistas();
  }

  loadSearchTransport(){
    this.searchTransportistasForm = this.fb.group({
      nombre:[''],
      origen:[''],
      destino:[''],
      fecha_inicio:['']
    });
  }

  loadTransportistas(){

    // Toma los valores de parámetros de búsqueda
    let pSearch = this.formatSearch(this.searchTransportistasForm.value);

    this.servicesService.getListTransportistas(pSearch).subscribe((resp: any) => {
      if (resp.code == '200') {

        this.dataSourceTransportistas = new MatTableDataSource(<listTransportista[]>resp.response);

        this.transportistasContratados = resp.response.map(respuesta=>{ return{
            codigo_anuncio:respuesta.codigo_anuncio,
            fechasalida:respuesta.fechasalida,
            ciudad_origen:respuesta.ciudad_origen,
            ciudad_destino:respuesta.ciudad_destino,
            monto:respuesta.monto,
            cargador:respuesta.cargador,
            nombre_transportista:respuesta.nombre_transportista
        }});
      }else{
        this.snackBar.open(resp['response'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
      this.dataSourceTransportistas.paginator = this.paginatorTransp;
    })
  }

  viewDetailsTravels(travel:any){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if(travel['estatus']=='FINALIZADO'){
      dialogConfig.data={typeUser:'Admin', idanuncio:travel.id}

      const dialogRef = this.dialog.open(ViajesPagoComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
      })
    }else{
      dialogConfig.data ={idanuncio: travel['id']};
      const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
    }
  }

  dowloadDocsTravels(travel:any){

    if(travel['estatus']=='FINALIZADO'){
      this.servicesService.getAnuncioFinalizado({idanuncio:travel.id}).subscribe((resp: any) => {

        if(resp['cod'] == 200 && resp['info_archivo'].length>0){
          let filesLlegada=resp['info_archivo']
                          .filter(f=>f.orden_llegada && f.orden_llegada.length>0)
                          .map((f,i)=>{return {nombre:`orden_llegada_${i+1}`,
                                               file:f.orden_llegada}});

          let filesLimpieza=resp['info_archivo']
                          .filter(f=>f.orden_limpio && f.orden_limpio.length>0)
                          .map((f,i)=>{return {nombre:`orden_limpieza_${i+1}`,
                                              file:f.orden_limpio}});
          this.downloadfiles([...filesLlegada, ...filesLimpieza]);
        }
      })
    }else{
      this.servicesService.getDataAnuncio({idanuncio:travel.id}).subscribe((resp: any) => {
        if(resp['code'] == 200 &&
            resp['msj'],
            resp['msj']['response'] &&
            resp['msj']['response']['trayecto'] &&
            resp['msj']['response']['trayecto'].length>0){

              let files = resp['msj']['response']['trayecto']
                            .filter(f=>f.url && f.url.length>0)
                            .map((f,i)=>{return {nombre:`orden_llegada_${i+1}`,file:f.url}});

              this.downloadfiles(files);
            }
      })
    }
  }


  downloadfiles(files:any[]){
    const observables = files.map(f =>
      this.servicesService.downloadObsfile(f.file)
    );

    forkJoin(observables).subscribe((responses:any[]) => {
      responses.forEach(response => {
        var file = new Blob([response.blob],{ type: response.blob.type })
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = response.nombre;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      });
    });
  }


  viewContact(data:listTransportista){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
      idcontratacion:data.idcontratacion,
      onlyRead:true
    };
    const dialogRef = this.dialog.open(ViajeContactoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {})
  }


  async viewPuja(idModal:any,dataAnuncio:any){
    this.ngxSpinnerService.show()

    const dataPuja = await this.loadDataPuja(dataAnuncio['idpuja']);
    this.ngbModal.open(idModal , { size: 'md', centered: true });
    this.dataPuja = dataPuja;

    this.stopSpinner()
  }

  async loadDataPuja(id:number){
    return new Promise((resolve) => {
      this.servicesService.getPuja({id_puja:id}).subscribe((resp: any) => {
        if (resp.code == 200) {
          resolve(resp.pujas[0]);
        }
        resolve(null);
      })
    });
  }

  //Depura la información del formulario para la búsqueda o filtro
  formatSearch(dataSearchForm:any){
    let pSearch={};
    Object.entries(dataSearchForm).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })
    return pSearch;
  }


  exportExcelViajes(){
    const headings = [['Codigo','Origen','Destino','Fecha Salida','Valor','Estado']];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.viajes, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, this.fileNameViajes);
  }


  exportExcelTransport(){
    const headings = [['Codigo','Fecha Salida','Ciudad Origen','Ciudad Destino','Monto','Cargador','Nombre Transportista']];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.transportistasContratados, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, this.fileNameTransportistas);
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 1700);
  }

  cerrarModal(modal:any){
    this.stopSpinner();
    modal.dismiss('Close click')
  }
}
