<ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
  <p style="font-size: 20px; color: white">Por favor espere...</p>
</ngx-spinner>

<div class="row">
    <div class="col-12">
        <div class="card ">
            <!-- <div class="card-body border-bottom">
                <div class="row g-3">
                    <div class="col-sm-9">
                        <form class="app-search d-xl-block">
                            <div class="position-relative">
                              <input type="text" class="form-control buscar" placeholder="Buscar">
                              <span class="bx bx-search-alt"></span>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-3">
                        <button type="button" class="btn btn-primary w-100">
                            <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div> -->

            <div class="card-body">
                <table style="width: 100%;">
                    <colgroup>
                        <col style="width:17%">
                    </colgroup>

                    <tr *ngFor="let puja of listPujas" class="border-bottom">
                      <td style="vertical-align:top;">
                        <img class="rounded-circle header-profile-user" src="{{puja.imagen_perfil}}" alt="Header Avatar"
                          style="height: 90px; width: 90px;">
                      </td>
                      <td>
                        <div class="col-12" style="margin-bottom: 50px;">
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <div><i>Código: {{puja.codigo_puja}}</i></div>
                              <label style="display: flex; flex-direction: column; font-size: 14px;">
                                <b>{{puja.alias}}</b></label>
                              <div>
                                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
                                <span style="font-size: 12px; color: #4A4A4A;">{{puja.puntaje | number : '1.0-1'}} </span>
                                <span style="padding-left: 3px; padding-right: 2px;"><img
                                    src="assets/images/transfriendly/point.png"></span>
                                <span style="font-size: 12px; color: #4A4A4A;"> Basado en {{puja.cantidad_calificaciones}}
                                  Calificaciones
                                </span>
                                <div>
                                  <img src="assets/images/transfriendly/camion2.png" alt="">
                                  {{puja.tipo_vehiculo}}
                                </div>
                              </div>

                              <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
                                <i class="fa fa-map-marker" style="color: #C9D002; padding-left: 3px;"></i>
                                <span>{{puja.direccion}}</span>
                              </div>
                            </div>
                            <div class="col-sm-3" *ngIf="puja.estatus != 'bloqueado'">
                              <button type="button"
                                      class="btn btn-secondary w-100"
                                      (click)="goChat(puja)">
                                Mensajes
                              </button>
                            </div>
                            <div class="col-sm-3" *ngIf="puja.estatus != 'bloqueado'">
                              <button type="button"
                                      class="btn btn-secondary w-100"
                                      [ngClass]="{'selected':puja.estatus == 'contratado'}"
                                      (click)="contratar(puja)"
                                      [disabled]="puja.estatus == 'contratado'">
                                      {{puja.estatus == 'contratado' ? 'Contratado' : 'Contratar'}}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row g-3">
                            <div class="col-sm-4"><label
                                style="display: flex; flex-direction: row; align-items: center; gap: 8px; font-size: 14px;">
                                Puja <b>{{puja.valor | formatNumberPipe }} €/Tn</b></label></div>
                            <div class="col-sm-4">
                              <!-- <label
                                style="display: flex; flex-direction: row; align-items: center; gap: 8px; font-size: 14px;">
                                Viajes realizados <b>{{puja.viajes_realizados}}</b></label> -->
                            </div>
                            <div *ngIf="puja.fecha_viaje" class="col-sm-4"><label
                              style="display: flex; flex-direction: row; align-items: center; gap: 8px; font-size: 14px;">
                              Fecha de salida <b>{{puja.fecha_viaje | date:'dd/MM/yyyy'}}</b></label></div>
                          </div>
                        </div>
                        <div style="text-align: justify;margin-bottom: 20px;">
                          {{puja.comentario}}
                        </div>
                        <div class="col-12">
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <label style="display: flex;align-items: center; gap: 8px; font-size: 14px;">
                                <i class="sidenav-link-icon"
                                  [ngClass]="puja.certificado ? 'fa fa-check-circle ok' : 'fa fa-times-circle fail'"></i>
                                <b style="color:black">Certificado de no adeudo verificado</b></label>
                            </div>
                            <div class="col-sm-6">
                              <label style="display: flex;align-items: center; gap: 8px; font-size: 14px;">
                                <i class="sidenav-link-icon"
                                  [ngClass]="puja.seguro ? 'fa fa-check-circle ok' : 'fa fa-times-circle fail'"></i>
                                <b style="color:black">Seguro de mercancías verificado</b></label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                </table>
            </div>

            <mat-paginator
              [length]="dataSource.length"
              [pageSize]="size"
              [pageIndex]="page"
              [pageSizeOptions]="[5,10]"
              (page)="linkListToPaginator($event)">
            </mat-paginator>
        </div>
    </div>
</div>




