import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { DialogAnuncioComponent } from '../dialog-anuncio/dialog-anuncio.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';

@Component({
  selector: 'app-descargas-solicitudes',
  templateUrl: './descargas-solicitudes.component.html',
  styleUrls: ['./descargas-solicitudes.component.scss']
})
export class DescargasSolicitudesComponent {

  searchForm: FormGroup;

  listOffers:any[] = [];
  dataSource:any[] = [];
  page:number=0;
  size:number=5;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private servicesService: ServicesService,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              public dialog: MatDialog){
    this.loadSearch();
    this.loadData();
  }

  loadSearch(){
    this.searchForm = this.fb.group({
      fecha_viaje:[''],
      provincia:[''],
      ciudad:[''],
      calle:[''],
      numero:[''],
      codigoPostal:['']
    });
  }

  loadData(){

    // Toma los valores de parámetros de búsqueda
    let pSearch={};
    Object.entries(this.searchForm.value).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })

    this.servicesService.getUnloadingRequests(pSearch).subscribe((resp: any) => {
      if (resp.cod == 200) {
        this.dataSource = resp.listado;
        this.linkListToPaginator({pageIndex: this.page, pageSize: this.size});
      }
    })
  }

  linkListToPaginator(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listOffers = this.dataSource.slice(from, to);
  }

  async viewModalAnuncio(iOffer:any){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data ={idanuncio: iOffer.idanuncio};

    const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
  }

  search(){
    this.listOffers = [];
    this.dataSource = [];
    this.loadData();
  }

  acceptUnloading(iOffer:any){
    this.setResponse(
      this.servicesService.setAcceptUnloadingRequests({idtrayecto:iOffer.trayecto,iddescarga:iOffer.id}),
      iOffer);
  }

  declineUnloading(iOffer:any){
    this.setResponse(
      this.servicesService.setDeclineUnloadingRequests({idtrayecto:iOffer.trayecto,iddescarga:iOffer.id})
      ,iOffer);
  }

  setResponse(event:any,iOffer:any){

    event.subscribe((resp: any) => {
      if (resp.cod == 200) {
        // Elimina la descarga del listado
        let idx = this.listOffers.findIndex(o => o.id == iOffer.id);
        this.listOffers.splice(idx,1);
        idx = this.dataSource.findIndex(o => o.id == iOffer.id);
        this.dataSource.splice(idx,1);

        this.snackBar.open(resp['info'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    })
  }

}
