import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';

@Component({
  selector: 'app-viaje-contacto',
  templateUrl: './viaje-contacto.component.html',
  styleUrls: ['./viaje-contacto.component.scss']
})
export class ViajeContactoComponent implements OnInit {

  contactForm: FormGroup;
  dataContact: any;
  loading:boolean = false;
  idcontratacion: number;
  readonly : boolean = false;
  id_operacion: number;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<ViajeContactoComponent>,
              private fb: FormBuilder,
              private servicesService:ServicesService){

    this.idcontratacion = data.idcontratacion;
    if(data.onlyRead) this.readonly = true;
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      idconductor:[0],
      identificacion_conductor: [{value:null,disabled:this.readonly},[Validators.required]],
      nombre_completo: [{value:null,disabled:this.readonly},[Validators.required]],
      num_telefono: [{value:null,disabled:this.readonly},[Validators.required]],
      matricula_trailer: [{value:null,disabled:this.readonly},[Validators.required]],
      matricula_cabina: [{value:null,disabled:this.readonly},[Validators.required]],
    })

    this.loadForm();
  }

  async loadForm() {
    this.loading = true;
    this.dataContact = await this.loadData(this.idcontratacion);
    this.setDataForm();
    this.id_operacion = (this.contactForm.value.idconductor==0)?1:2; // 1 = Guardar Registro ; 2 = Actualizar Registro
  }

  setDataForm(){
    if(this.dataContact){
      this.contactForm.patchValue({
        idconductor:this.dataContact.id,
        identificacion_conductor:this.dataContact.identificacion_conductor,
        nombre_completo:this.dataContact.nombre_completo,
        num_telefono:this.dataContact.num_telefono,
        matricula_trailer:this.dataContact.matricula_trailer,
        matricula_cabina:this.dataContact.matricula_cabina
      });
    }

    this.loading = false;
  }

  async loadData(id:number){
    return new Promise((resolve) => {
      this.servicesService.queryDriver(id).subscribe((resp: any) => {
        if (resp.response) {
          resolve(resp.response);
        }
        resolve(null);
      })
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  async save(){
    if(this.contactForm.valid){
      let data ={
        idcontratacion:this.idcontratacion,
        idconductor:this.contactForm.value.idconductor,
        operacion:this.id_operacion,
        identificacion_conductor:this.contactForm.value.identificacion_conductor,
        nombre_completo:this.contactForm.value.nombre_completo,
        telefono:this.contactForm.value.num_telefono,
        mat_trailer:this.contactForm.value.matricula_trailer,
        mat_cabina:this.contactForm.value.matricula_cabina
      }

      await this.servicesService.setDriver(data).subscribe((resp: any) => {
        if (resp.cod ==200) {
          this.dialogRef.close({result:true});
        }else{
          this.dialogRef.close({result:false, message:'No se lógro guardar los datos del conductor'});
        }
      })

    }
  }

  async search(){
    let data ={identificacion:this.contactForm.value.identificacion_conductor};
    this.loading = true;
    await this.servicesService.searchDriver(data).subscribe((resp: any) => {
      if (resp.cod == 1) {
        this.dataContact = resp.ifo_conductor;
        this.dataContact.idconductor = resp.ifo_conductor.id;
        this.dataContact.identificacion_conductor = data.identificacion;
      }else{
        this.dataContact = {
          idconductor:0,
          identificacion_conductor: data.identificacion,
          nombre_completo:null,
          num_telefono:null,
          matricula_trailer:null,
          matricula_cabina:null
        }
      }
      this.setDataForm();
    })
  }
}
