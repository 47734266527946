import { Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { ServicesService } from '../../services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

interface Puja {
  idpuja: number,
  codigo_puja:string,
  created_at: Date,
  id_usuario:number,
  imagen_perfil:string,
  nombre_usuario: string,
  alias:string,
  puntaje: number,
  calificaciones: number,
  direccion: string,
  valor: number,
  fecha_viaje: Date,
  viajes_realizados: number,
  comentario: string,
  certificado: boolean,
  seguro: boolean
  estatus: string /** 'contratado': Puja seleccionada,
                      'bloqueado': Puja NO habilitada ya que existe una puja contratada para el anuncio
                      'activo': Puja habilitada para ser contratada  */
};
@Component({
  selector: 'app-gestion-pujas',
  templateUrl: './gestion-pujas.component.html',
  styleUrls: ['./gestion-pujas.component.scss']
})
export class GestionPujasComponent {

  @Input() idAnuncio: string;
  @Output() vMessage = new EventEmitter<any>();

  row={
    idpuja: 0,
    created_at:null,
    id_usuario:0,
    imagen_perfil:"",
    nombre_usuario: null,
    puntaje: 0,
    cantidad_calificaciones: 0,
    direccion: "---",
    valor: 0,
    fecha_viaje: null,
    viajes_realizados: 0,
    comentario: `---`,
    certificado: true,
    seguro: true,
    estatus:''
  }

  listPujas:Puja[] = [];
  dataSource:Puja[] = [];
  page:number=0;
  size:number=5;

  constructor(private servicesService: ServicesService,
              private snackBar:MatSnackBar,
              private router:Router,
              private ngxSpinnerService:NgxSpinnerService){}

  ngOnInit(){
    this.loadData();
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 2000);
  }

  linkListToPaginator(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listPujas = this.dataSource.slice(from, to);
  }

  loadData(){
    this.servicesService.getCargadorListPujas({id_anuncio:this.idAnuncio}).subscribe((resp: any) => {

      if (resp.status == 'ok') {
        this.listPujas = resp.pujas.map(o=>{
          return{... this.row, ... o}
        })

        this.dataSource = this.listPujas;
        this.linkListToPaginator({pageIndex: this.page, pageSize: this.size});

      }
    })
  }

  contratar(puja:any){
    if(puja['contratado']) return;
    Swal.fire({
      title:`Confirmanos la contratación al Transportista de la puja '${puja.codigo_puja}' para este anuncio`,
      icon:'question',
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      showDenyButton: true,
    }).then(resp=>{
      if (resp.isConfirmed) {
        this.ngxSpinnerService.show();

        this.servicesService.contratarTransportista({id_puja:puja.idpuja}).subscribe((resp: any) => {
          if(resp.code == 200){
            // Informa en el botón que la puja fué contratada
            this.listPujas = [];
            this.dataSource = [];
            this.loadData();
            Swal.fire({ icon: 'success', text: 'Transportista Contratado!' });
          }else{
            this.snackBar.open('No se logró la contratación para este anuncio','Ok',{
              duration:3000,
              horizontalPosition:right,
              verticalPosition:bottom,
            });
          }
        })

        this.stopSpinner();
      }
    })
  }

  goChat(dataPuja:any){
    let data = {
      id_to_user: dataPuja.id_usuario,
      idanuncio: this.idAnuncio
    }

    this.servicesService.createChat(data).subscribe((resp: any) => {
      if(resp.code == 200){
        // console.log(resp);
        // this.vMessage.emit({id_sala:resp.id_sala,
        //   listsUser:'pujas'});
          localStorage.setItem('goMsg',JSON.stringify({id_sala:resp.id_sala,
            listsUser:'pujas'}))
          this.router.navigate(['/pagina/principal/cargador']);
      }else{
        let msg = "No es posible crear sala de chat";
        this.snackBar.open(msg,'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    })
  }
}
