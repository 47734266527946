import { Component, OnInit } from '@angular/core';
import { AdministradorService } from '../../pages/dashboards/services/administrador.service';
import { Lista } from '../../core/models/lista.models';
import { NgxSpinnerService } from "ngx-spinner";
import { Perfil } from 'src/app/core/models/perfiles.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServicesService } from '../../pages/dashboards/services/services.service';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCalificaComponent } from '../dialog-califica/dialog-califica.component';

@Component({
  selector: 'app-lista-descargadores',
  templateUrl: './lista-descargadores.component.html',
  styleUrls: ['./lista-descargadores.component.scss']
})

export class ListaDescargadoresComponent implements OnInit {
  public page = 1;
	public pageSize = 10;
	public collectionSize = 0;
  public pagina:Lista[];
  public descargadores :Lista[];
  public usuario:Perfil;
  private idUser;
  private idPerfil = 3;
  public tag:number = 1;
  public perfiles_activos:any[] = [];

  searchDescargador: FormGroup;

  initUserScore = {
    id:null,
    alias: null,
    email: null,
    avatar: null,
    calificaciones:[]
  };
  userScore:any = {};

  private modalRef: any;

  listUnloads: any[] = [];
  dataSourceUnloadsHistory: any[] = [];
  pageModal:number=0;
  sizeModal:number=5;

  constructor(private administradorService:AdministradorService,
              private ngxSpinnerService:NgxSpinnerService,
              private ngbModal:NgbModal,
              private fb: FormBuilder,
              private servicesService:ServicesService,
              private snackBar:MatSnackBar,
              public matDialog: MatDialog){}

  ngOnInit(): void {
    this.loadSearchUsers();
    this.getDescargadores();
    this.perfiles_activos = [];
  }

  stopSpinner(){
    setTimeout(() => { this.ngxSpinnerService.hide(); }, 1000);
  }

  refreshPagina() {
    this.pagina = this.descargadores.map((data, i) => ({ id: i + 1, ...data })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  loadSearchUsers(){
    this.searchDescargador = this.fb.group({
      nombre:['']
    });
  }

  searchUsuarios(){
    this.pagina = [];
    this.getDescargadores()
  }

  getDescargadores(){
    this.ngxSpinnerService.show();

    // Toma los valores de parámetros de búsqueda
    let pSearch = this.formatSearch(this.searchDescargador.value);

    this.administradorService.consultarUsuarioPerfil(pSearch,this.idPerfil).subscribe(resp=>{
      console.log('Descargadores', resp);
      if(resp.status == "ok" && resp.code == "200"){
        this.descargadores = resp.usuarios;
        this.collectionSize = this.descargadores.length;
        this.refreshPagina();
      }else{
        this.snackBar.open(resp['usuarios'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    });

    this.stopSpinner();
  }

  largeModal(largeDataModal: any, id?:number){
    this.idUser = id;
    this.modalRef = largeDataModal;
    this.ngbModal.open(largeDataModal, { size: 'lg', centered: true });
    this.consultarUsuario(id);
    this.loadUnloadsHistory(id)
  }

  consultarUsuario(id:number){
    this.tag = 1;
    this.administradorService.consultarUsuario(id).subscribe(resp =>{
      if(resp.status == "ok"){
        if(resp.usuario != null){
          this.usuario = resp.usuario[0];
          this.perfiles_activos = resp.perfiles_activos;
        }
      }
    })
    this.loadScore(id);
  }

  loadScore(idUser){
    this.userScore = this.initUserScore;
    this.servicesService.consultaCalificaciones(idUser).subscribe((resp:any) =>{
      if(resp.code == 200 && resp.usuario){
        this.userScore = {
          id:resp.usuario[0].id,
          alias: resp.usuario[0].alias,
          email: resp.usuario[0].email,
          avatar: resp.usuario[0].avatar,
          calificaciones:resp.calificaciones,
          total_calificaciones:resp.total_calificaciones,
          promedio:resp.promedio
        }
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))
  }

  loadUnloadsHistory(idUser){
    this.servicesService.getListFinishedUnloads(idUser).subscribe((resp: any) => {
      if (resp.cod == '200') {
        this.dataSourceUnloadsHistory = resp.listado;
        this.loadPage({pageIndex: this.pageModal, pageSize: this.sizeModal});
      }
    })
  }

  loadPage(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    console.log(obj.pageIndex, obj.pageSize);
    this.listUnloads = this.dataSourceUnloadsHistory.slice(from, to);
  }

  changeTag(t:number){
    this.tag = t;
  }

  //Depura la información del formulario para la búsqueda o filtro
  formatSearch(dataSearchForm:any){
    let pSearch={};
    Object.entries(dataSearchForm).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })
    return pSearch;
  }

  eliminarUsuario(id:number){
    Swal.fire({
      title: 'Estas seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicesService.eliminarUsuario(id).subscribe(resp =>{
          if(resp.code == 200){
            Swal.fire('Eliminado!','Usuario ha sido eliminado correctamente','success');
            this.getDescargadores();
          }
        },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }));
      }

    })
  }


  modalScore(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id:this.idUser,
      perfil: this.idPerfil}

    const dialogRef = this.matDialog.open(DialogCalificaComponent,{
      ... dialogConfig,
      width:'550px',
      height:'460px'
    });

    dialogRef.afterClosed().subscribe(resp => {
      this.largeModal(this.modalRef, this.idUser)
    })
  }
}
